import css from '../index.module.scss';

export const themeList = [
  {
    key: 1,
    name: 'Primo',
    img001: 'primo_001', //avif,webp
    title001: <>Primo&nbsp;Tiramisu</>,
    title002: (
      <>
        元来の要素をそれぞれ極めた
        <br />
        本格的なティラミス
      </>
    ),
    title003: (
      <div>
        あなた好みに
        <br className={css.indent} />
        カスタマイズできる
        <br />
        新感覚ティラミス
      </div>
    ),

    imageSection001: {
      img: 'primo_002',
      description: (
        <>
          ティラミスとは、何なのか。
          <br className={css.indent} />
          <span>600</span>
          種類以上のティラミスを食べ、マスカルポーネチーズとザバイオーネで作った軽いのに濃厚なクリーム、クリームの口どけを邪魔しないビスキュイ・ジョコンド、ジューシーな食感と味にまとまりを与えるエスプレッソ。このティラミス本来の
          <span>3</span>つを絶妙に組み合わせました。
          <br />
          <span>BENE REGALO</span>にとって最初のティラミスをお楽しみください。
        </>
      ),
      englishText: {
        line1: 'PRIMO',
        line2: 'TIRAMISU',
        lineMargin: [64, 153, 271],
      },
      boxText: '始まりのティラミス',
    },

    imageSection002: {
      img: 'primo_feuillantine',
      description: (
        <>
          ティラミスの滑らかな食感に、程よく溶け込む魅惑の食感。フィアンティーヌという素材をチョコレートでコーティングし、ティラミスに楽しい変化を加えるカスタマイズとして選びました。
          <br />
          そのまま食べても、ほろ苦いサクサクお菓子になります。あなた好みで足してみてください。
        </>
      ),
      englishText: {
        line1: 'CHOCOLAT',
        line2: 'FEUILLANTINE',
        lineMargin: [64, 100, 51],
      },
      boxText: 'サクサク食感',
    },

    imageSection003: {
      img: 'primo_sauce',
      description: (
        <>
          ティラミスの味のまとまりを担うエスプレッソは、好みが人によって大きく異なる要素でもあります。エスプレッソの味わい深さを足して、より大人向けのティラミスにしたい方はエスプレッソソースがおすすめです。濃いカフェモカのような味わいでティラミスの美味しさを損なわずによりよく違った品へ昇華します。
        </>
      ),
      englishText: {
        line1: 'ESPRESSO',
        line2: 'SAUCE',
        lineMargin: [90, 203, 378],
      },
      boxText: 'ほどよい苦味',
    },

    text010: <>食べ方いろいろ。</>,
    text011: <>NORMAL</>,
    text012: (
      <>
        何もかけずに
        <br />
        そのままで。
      </>
    ),
    text013: (
      <>
        ESPRESSO
        <br />
        SAUCE
      </>
    ),
    text014: (
      <>
        甘さ控えめの
        <br />
        エスプレッソソース
        <br />
        をかけて。
      </>
    ),
    text015: (
      <>
        CHOCOLAT
        <br />
        FEUILLANTINE
      </>
    ),
    text016: (
      <>
        サクサク食感の
        <br />
        フィアンティーヌ
        <br />
        をアクセントに。
      </>
    ),
    text017: <>BOTH</>,
    text018: <>両方かけて、贅沢に。</>,
    img005: 'primo_003',
    img006: 'primo_004',
    img007: 'primo_005',
    img008: 'primo_006',
  },
  {
    key: 2,
    name: 'Bianco',
    img001: 'bianco_001',
    title001: <>Tiramisu&nbsp;Bianco</>,
    title002: (
      <>
        チーズにフォーカスした
        <br />
        白いティラミス
      </>
    ),
    title003: (
      <div>
        あなた好みに
        <br className={css.indent} />
        カスタマイズできる
        <br />
        新感覚ティラミス
      </div>
    ),
    imageSection001: {
      img: 'bianco_002',
      description: (
        <>
          ティラミスを構成する重要な要素はマスカルポーネを主とするチーズとエスプレッソの2つ。
          <br />
          そのチーズにフォーカスをしつつ、エスプレッソ要素もしっかりと入れた純白のティラミスは、白いのに確かなコーヒーを感じながらチーズのコクや濃厚さを感じられる新しいティラミスとなっています。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'BIANCO',
        lineMargin: [64, 153, 271],
      },
      boxText: '純白のティラミス',
    },
    imageSection002: {
      img: 'bianco_feuillantine',
      description: (
        <>
          ティラミスの滑らかな食感に、程よく溶け込む魅惑の食感。フィアンティーヌという素材をチョコレートでコーティングし、ティラミスに楽しい変化を加えるカスタマイズとして選びました。
          <br />
          そのまま食べても、ほろ苦いサクサクお菓子になります。あなた好みで足してみてください。
        </>
      ),
      englishText: {
        line1: 'FROMAGE',
        line2: 'FEUILLANTINE',
        lineMargin: [64, 100, 51],
      },
      boxText: 'サクサク食感',
    },
    imageSection003: {
      img: 'bianco_sauce',
      description: (
        <>
          ティラミスの味のまとまりを担うエスプレッソは、好みが人によって大きく異なる要素でもあります。エスプレッソの味わい深さを足して、より大人向けのティラミスにしたい方はエスプレッソソースがおすすめです。濃いカフェモカのような味わいでティラミスの美味しさを損なわずによりよく違った品へ昇華します。
        </>
      ),
      englishText: {
        line1: 'FROMAGE',
        line2: 'SAUCE',
        lineMargin: [64, 153, 271],
      },
      boxText: 'ほどよい苦味',
    },
    text010: <>食べ方はあなた次第。</>,
    text011: <>NORMAL</>,
    text012: (
      <>
        何もかけずに
        <br />
        そのままで。
      </>
    ),
    text013: (
      <>
        FROMAGE
        <br />
        SAUCE
      </>
    ),
    text014: (
      <>
        甘さ控えめの
        <br />
        フロマージュソース
        <br />
        をかけて。
      </>
    ),
    text015: (
      <>
        FROMAGE
        <br />
        FEUILLANTINE
      </>
    ),
    text016: (
      <>
        サクサク食感の
        <br />
        フィアンティーヌ
        <br />
        をアクセントに。
      </>
    ),
    text017: <>BOTH</>,
    text018: <>両方かけて、贅沢に。</>,
    img005: 'bianco_003', //avif,webp
    img006: 'bianco_004', //avif,webp
    img007: 'bianco_005', //avif,webp
    img008: 'bianco_006', //avif,webp
  },
  {
    key: 3,
    name: 'Nero',
    img001: 'nero_001',
    title001: <>Tiramisu&nbsp;Nero</>,
    title002: (
      <>
        コーヒーにフォーカスした
        <br />
        黒いティラミス
      </>
    ),
    title003: (
      <div>
        あなた好みに
        <br className={css.indent} />
        カスタマイズできる
        <br />
        新感覚ティラミス
      </div>
    ),
    imageSection001: {
      img: 'nero_002',
      description: (
        <>
          ティラミスを構成する重要な要素はマスカルポーネを主とするチーズとエスプレッソの2つ。
          <br />
          そのチーズにフォーカスをしつつ、エスプレッソ要素もしっかりと入れた純白のティラミスは、白いのに確かなコーヒーを感じながらチーズのコクや濃厚さを感じられる新しいティラミスとなっています。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'NERO',
        lineMargin: [64, 153, 271],
      },
      boxText: '純黒のティラミス',
    },
    imageSection002: {
      img: 'nero_feuillantine',
      description: (
        <>
          ティラミスの滑らかな食感に、程よく溶け込む魅惑の食感。フィアンティーヌという素材をチョコレートでコーティングし、ティラミスに楽しい変化を加えるカスタマイズとして選びました。
          <br />
          そのまま食べても、ほろ苦いサクサクお菓子になります。あなた好みで足してみてください。
        </>
      ),
      englishText: {
        line1: 'CAFE',
        line2: 'FEUILLANTINE',
        lineMargin: [64, 100, 51],
      },
      boxText: 'サクサク食感',
    },
    imageSection003: {
      img: 'nero_sauce',
      description: (
        <>
          ティラミスの味のまとまりを担うエスプレッソは、好みが人によって大きく異なる要素でもあります。エスプレッソの味わい深さを足して、より大人向けのティラミスにしたい方はエスプレッソソースがおすすめです。濃いカフェモカのような味わいでティラミスの美味しさを損なわずによりよく違った品へ昇華します。
        </>
      ),
      englishText: {
        line1: 'CAFE',
        line2: 'SAUCE',
        lineMargin: [64, 153, 271],
      },
      boxText: '深めの苦み',
    },
    text010: <>食べ方はあなた次第。</>,
    text011: <>NORMAL</>,
    text012: (
      <>
        何もかけずに
        <br />
        そのままで。
      </>
    ),
    text013: (
      <>
        CAFE
        <br />
        SAUCE
      </>
    ),
    text014: (
      <>
        甘さ控えめの
        <br />
        コーヒーソース
        <br />
        をかけて。
      </>
    ),
    text015: (
      <>
        CAFE
        <br />
        FEUILLANTINE
      </>
    ),
    text016: (
      <>
        サクサク食感の
        <br />
        フィアンティーヌ
        <br />
        をアクセントに。
      </>
    ),
    text017: <>BOTH</>,
    text018: <>両方かけて、贅沢に。</>,
    img005: 'nero_003',
    img006: 'nero_004',
    img007: 'nero_005',
    img008: 'nero_006',
  },
  {
    key: 4,
    name: 'Primo',
    img001: 'financier_001', //avif,webp
    title001: <>Tiramisu&nbsp;Financier</>,
    title002: (
      <>
        ティラミスを詰め込んだ
        <br />
        至極のフィナンシェ
      </>
    ),
    title003: (
      <div>
        あなた好みに
        <br className={css.indent} />
        カスタマイズできる
        <br />
        新感覚ティラミス
      </div>
    ),
    imageSection001: {
      img: 'financier_002',
      description: (
        <>
          ティラミス製造で出る卵白を有効活用し、たっぷりのバターを使った生地にチーズを練り込み、カフェリーヌエスプレッソとホワイトチョコを乗せて焼き上げた新しいティラミスの形。袋を開けた瞬間香るエスプレッソはコーヒーが好きな方に至福をもたらすことでしょう。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'FINANCIER',
        lineMargin: [64, 153, 271],
      },
      boxText: '焼きティラミス',
    },
    imageSection002: {
      img: 'financier_003',
      description: (
        <>
          贈り物に適したシンプルで高級感のある箱に10個のフィナンシェを入れています。
          <br />
          結婚式の引き出物やお歳暮、お中元など様々な用途で人気なティラミスフィナンシェをお試しください。
        </>
      ),
      englishText: {
        line1: 'SPECIAL',
        line2: 'BOX',
        lineMargin: [90, 203, 378],
      },
      boxText: '高級感のある箱',
    },
  },
  {
    key: 5,
    name: 'Bianco',
    img001: 'macaron_bianco_001',
    title001: <>Tiramisu&nbsp;Macaron&nbsp;Bianco</>,
    title002: (
      <>
        チーズ生地
        <br />
        ×
        <br />
        コーヒークリーム
      </>
    ),
    title003: (
      <div>
        コーヒー香る
        <br />
        白いマカロン
      </div>
    ),
    imageSection001: {
      img: 'macaron_bianco_002',
      description: (
        <>
          マカロンの生地が膨らむギリギリの糖度に落とし、チーズの風味を出来る限り高めた生地に、苦味とカカオ感が調和したコーヒークリームを挟めました。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'MACARON',
        lineMargin: [64, 153, 271],
      },
      boxText: 'ティラミスマカロン',
    },
    imageSection002: {
      img: 'macaron_bianco_003',
      description: (
        <>
          白いティラミスマカロンは、コーヒーやエスプレッソが好きな方に向けたティラミスマカロンとなっています。
          <br />
          ほろ苦いクリームで優雅なひと時をお過ごしください。
        </>
      ),
      englishText: {
        line1: 'CAFE',
        line2: 'BIANCO',
        lineMargin: [54, 143, 261],
      },
      boxText: '白いコーヒー',
    },
  },
  {
    key: 6,
    name: 'Nero',
    img001: 'macaron_nero_001',
    title001: <>Tiramisu&nbsp;Macaron&nbsp;Nero</>,
    title002: (
      <>
        コーヒー生地
        <br />
        ×
        <br />
        チーズクリーム
      </>
    ),
    title003: (
      <div>
        チーズを感じる
        <br />
        黒いマカロン
      </div>
    ),
    imageSection001: {
      img: 'macaron_nero_002',
      description: (
        <>
          マカロンの生地が膨らむギリギリの糖度に落とし、コーヒーの風味を出来る限り高めた生地に、クリーミーなチーズクリームを挟めました。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'MACARON',
        lineMargin: [64, 153, 271],
      },
      boxText: 'ティラミスマカロン',
    },
    imageSection002: {
      img: 'macaron_nero_003',
      description: (
        <>
          黒いティラミスマカロンは、チーズのクリーミーさが好きな方に向けたティラミスマカロンとなっています。
          <br />
          クリーミーなチーズ感を感じながら、美味しさの愉悦に浸ってください。
        </>
      ),
      englishText: {
        line1: 'FROMAGE',
        line2: 'NERO',
        lineMargin: [100, 213, 388],
      },
      boxText: '黒いチーズ',
    },
  },
  {
    key: 7,
    name: 'Bianco',
    img001: 'gelato_001',
    title001: <>Mascarpone&nbsp;Gelato</>,
    title002: (
      <>
        最高にクリーミーな
        <br />
        マスカルポーネ
      </>
    ),
    imageSection001: {
      img: 'mascarpone_gelato_002',
      description: (
        <>
          マスカルポーネチーズをそのまま食べたことはありますか？
          <br />
          マスカルポーネはチーズのクリーミーさだけがあるような淡白なチーズ。
          <br />
          そのマスカルポーネをふんだんに使用し、濃厚なジェラートにしました。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'GELATO',
        lineMargin: [64, 153, 271],
      },
      boxText: 'ティラミスジェラート',
    },
  },
  {
    key: 8,
    name: 'Nero',
    img001: 'gelato_001',
    title001: <>Espresso&nbsp;Gelato</>,
    title002: (
      <>
        程よい苦味、深い味わい
        <br />
        エスプレッソ
      </>
    ),
    imageSection001: {
      img: 'espresso_gelato_002',
      description: (
        <>
          エスプレッソをそのままジェラートにしたと思ってもらえるような濃い味を目指しました。
          <br />
          ダークココアのクッキーでアクセントもあるジェラートになっています。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'GELATO',
        lineMargin: [64, 153, 271],
      },
      boxText: 'ティラミスジェラート',
    },
  },
  // MEMO: 今後復活のため残す
  // {
  //   key: 9,
  //   name: 'Matcha',
  //   img001: 'matcha_001',
  //   title001: <img src={`${process.env.PUBLIC_URL}/top/matcha_title_001.webp`} alt="" className={css.matcha_title} />,
  //   title002: (
  //     <p className={css.fontTaFugaFude}>
  //       南山城村の高級春摘み抹茶
  //       <br />
  //       オクミドリ使用
  //     </p>
  //   ),
  //   img002: 'matcha_002',
  //   text001: (
  //     <p className={css.fontTaFugaFude}>
  //       ナダルさんの故郷である南山城村の最高級抹茶をふんだんに使用し、濃厚な抹茶マスカルポーネクリームを実現しました。
  //       <br />
  //       その抹茶の深い味わいをご堪能ください。
  //     </p>
  //   ),
  //   text002: (
  //     <>
  //       <div className={css.verticalFirst}>OKUMIDORI</div>
  //       <div className={css.verticalSecond}>MATCHA</div>
  //     </>
  //   ),
  //   text003: (
  //     <>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>厳</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>選</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>さ</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>れ</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>た</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>濃</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>い</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>抹</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>茶</p>
  //     </>
  //   ),
  //   img003: 'matcha_003', //avif,webp
  //   text004: (
  //     <>
  //       とことん良い抹茶を感じてもらうために後がけできる追い抹茶をつけました。
  //       <br />
  //       南山城村の良質な抹茶を強く感じられ、結婚式の引き出物やお歳暮、お中元など様々な用途で選ばれる逸品です。
  //     </>
  //   ),
  //   text005: (
  //     <>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>追</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>い</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>抹</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>茶</p>
  //     </>
  //   ),
  //   text006: (
  //     <>
  //       <div className={classNames(css.abs2, css.abs2Matcha)}>PLUS</div>
  //       <div className={classNames(css.abs3, css.abs3Matcha)}>MATCHA</div>
  //     </>
  //   ),
  //   img004: 'matcha_004', //avif,webp
  //   text007: (
  //     <>
  //       可愛さもありつつ、高級感もある容器と色合いは、贈り物に適した静かで厳かな趣があるようにデザインしました。
  //       ご自身で食べるだけではなく、大切な人へのプレゼントとして、選んでいただけると幸いです。
  //     </>
  //   ),
  //   text008: (
  //     <>
  //       <div className={classNames(css.abs1, css.abs1Matcha)}>PUDDING</div>
  //       <div className={classNames(css.abs2, css.abs2Matcha)}>TIRAMISU</div>
  //     </>
  //   ),
  //   text009: (
  //     <>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>て</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>ぃ</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>ら</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>み</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>す</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>ぷ</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>り</p>
  //       <p className={classNames(css.fontTaFugaFude, css.verticalText)}>ん</p>
  //     </>
  //   ),
  // },
  {
    key: 9,
    name: 'Primo',
    img001: 'bun_001',
    title001: <>ティラミスまん</>,
    title002: (
      <>
        今までにない
        <br />
        暖かいティラミスへの挑戦
      </>
    ),
    title003: (
      <div>
        あなた好みに
        <br className={css.indent} />
        カスタマイズできる
        <br />
        新感覚ティラミス
      </div>
    ),
    imageSection001: {
      img: 'bun_002',
      description: (
        <>
          北海道産マスカルポーネをふんだんに使ったティラミスクリームをブレンドコーヒーを混ぜ合わせた生地で包みました。
        </>
      ),
      englishText: {
        line1: 'TIRAMISU',
        line2: 'BUN',
        lineMargin: [100, 213, 388],
      },
      boxText: 'ティラミスまん',
    },
    imageSection002: {
      img: 'primo_sauce',
      description: (
        <>
          Primo Tiramisuで人気のエスプレッソソースをつけることで、より味わい深いティラミスとなります。
          <br />
          濃いカフェモカのような味わいでティラミスの美味しさを損なわずによりよく違った品へ昇華します。
        </>
      ),
      englishText: {
        line1: 'ESPRESSO',
        line2: 'SAUCE',
        lineMargin: [90, 203, 378],
      },
      boxText: 'ほどよい苦味',
    },
  },
  {
    key: 10,
    name: 'Primo',
    img001: 'namachoco_001',
    title001: <>生チョコティラミス</>,
    title002: (
      <>
        ティラミスをそのまま
        <br />
        とろける生チョコに
      </>
    ),
    imageSection001: {
      img: 'namachoco_002',
      description: <>自慢のPrimo Tiramisuを生チョコで再現し、滑らかな口溶けな濃厚ティラミスが完成しました。</>,
      englishText: {
        line1: 'NAMA-',
        line2: 'CHOCOLATE',
        lineMargin: [64, 153, 271],
      },
      boxText: '生チョコティラミス',
    },
  },
];
