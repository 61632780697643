import { useState, useEffect } from 'react';
import shopCss from './index.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../lp-legacy/Footer';

export function Akigawa() {
  // window.addEventListener('DOMContentLoaded', () => {
  //   document.documentElement.style.setProperty('--outer-height', `${window.innerHeight}px`);
  // });

  useEffect(() => {
    document.documentElement.style.setProperty('--menuWidth', `295`);
    document.documentElement.style.setProperty('--menuHeight', `409`);
  }, []);

  const [isMenuClicked, setIsMenuClicked] = useState('menu');

  const handClick = () => {
    if (isMenuClicked === 'menu') {
      setIsMenuClicked('spread');
    } else {
      setIsMenuClicked('menu');
    }
  };

  return (
    <div className={classNames(shopCss.shopPage)}>
      <Link to={'/'} className={shopCss.landingPageLink}>
        <div className={shopCss.cartButton}>
          <img className={shopCss.cartIcon} src={`${process.env.PUBLIC_URL}/cartLogo2.webp`} alt="" />
          <div className={shopCss.buttonText}>購入ページ</div>
        </div>
      </Link>
      <div className={isMenuClicked === 'spread' ? shopCss.blackCover : shopCss.dNone} onClick={handClick}></div>
      <div className={isMenuClicked === 'spread' ? shopCss.crossIcon : shopCss.dNone} onClick={handClick}>
        ✖
      </div>

      <div className={shopCss.body}>
        <div className={shopCss.sec1}>
          <div className={shopCss.title}>BENE REGALO 秋川本店</div>
          <img src={`${process.env.PUBLIC_URL}/akigawa.webp`} alt="" className="" />
        </div>
        <div className={shopCss.sec2}>
          <div className={shopCss.title}>MENU</div>
          <img
            src={`${process.env.PUBLIC_URL}/menu_yoyogi.webp`}
            alt=""
            onClick={handClick}
            className={shopCss[isMenuClicked]}
          />
        </div>
        <div className={shopCss.sec3}>
          <div className={shopCss.title}>予約・取り置き</div>
          <div className={shopCss.sec3flex}>
            <a href="tel:042-808-7933" className={shopCss.box1}>
              <img src={`${process.env.PUBLIC_URL}/phone.webp`} alt="" className="" />
              <p>042-808-7933</p>
            </a>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdMKcX9QRaADWmC0_8LZ5snhrvOFFwmPQBY1TekCdctY6lCIA/viewform"
              target="_blank"
              rel="noopener noreferrer"
              className={shopCss.box1}
            >
              <img src={`${process.env.PUBLIC_URL}/reserve.webp`} alt="" className="" />
              <p>WEB予約</p>
            </a>
          </div>
          <div className={shopCss.sec3flex2}>
            <img src={`${process.env.PUBLIC_URL}/warning.webp`} alt="" className="" />
            <p>当店は、2-3人が座れるベンチがございますが、基本的にテイクアウトのお店となります。</p>
          </div>
        </div>
        <div className={shopCss.sec4}>
          <div className={shopCss.title}>アクセス</div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.8474988251223!2d139.28206448273548!3d35.72996890184922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601921c7df270435%3A0xbd02a2f7b8587fcd!2z44OG44Kj44Op44Of44K55bCC6ZaA5bqXIEJFTkUgUkVHQUxPIOeni-W3neacrOW6lw!5e0!3m2!1sja!2sjp!4v1683359823050!5m2!1sja!2sjp"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className={shopCss.map}
          ></iframe>
        </div>
        <div className={shopCss.sec5}>
          <div className={shopCss.title}>口コミ</div>
          <img src={`${process.env.PUBLIC_URL}/akigawa_review1.webp`} alt="" className={shopCss.review} />
          <img src={`${process.env.PUBLIC_URL}/akigawa_review2.webp`} alt="" className={shopCss.review} />

          {/* <Twemb/> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

// twitterのタイムライン埋め込み（今後使うかもしれないので一旦残してます）
// let isLoadWidgets = false;
// export const Twemb = () => {
//   useEffect(() => {
//     if (!isLoadWidgets) {
//       const s = document.createElement('script');
//       s.setAttribute('src', 'https://platform.twitter.com/widgets.js');
//       document.body.appendChild(s);
//       isLoadWidgets = true;
//     }
//   }, []);
//   return (
//     <a
//       className="twitter-timeline"
//       data-width="500"
//       data-height="400"
//       data-theme="light"
//       href="https://twitter.com/bene_regalo?ref_src=twsrc%5Etfw"
//     >
//       A Twitter List by
//     </a>
//   );
// };
