import { useEffect, useState, createRef, useCallback } from 'react';

// react-libs
import { Link, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdClose } from 'react-icons/md';

// slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// swiper
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

// firebase
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../zz_general/utils/configs/firebase';

// local components
import { setReferrerData, sendRefererData } from './utils';
import { MiniBoxButton } from './components';
import { Cart } from './components/cart';
import { themeList } from './utils/themeList';
import { ImageSection } from './components/imageSection';
import Footer from './components/footer';
import { FirstView } from './components/firstview';

// styles
import classNames from 'classnames';
import css from './index.module.scss';
import { StyledDiv } from '../utils/styled';
import { CartIconCon, CountIcon, ItemPanelCon, PriceCon, PriceFonts, TotalPrice } from './scss';

// recoil states
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { termsAndPolicyState, firstViewState, bannerState } from '../../lp-legacy/atoms';
import { LPCartState, LPtotalCountState, LPtotalPriceState, sumUpCart } from '../store/atoms';

export const LPHome = () => {
  // #region Variables

  const ref = createRef();
  const search = useLocation().search;
  const query2 = new URLSearchParams(search);
  // MEMO: naraduさんYoutube部分がスクロールするごとに再読み込みがかかるのでコメントアウトした
  // const [itemPanelHeight, setItemPanelHeight] = useState(0);
  const [_footerHeight, setFooterPanelHeight] = useState(0);
  const [shopPageLink, setShopPageLink] = useState(false);
  const [shopList, setShopList] = useState(false);
  const [activateDescription1, setActivateDescription1] = useState(false);
  const [activateDescription2, setActivateDescription2] = useState(false);
  const [activateDescription3, setActivateDescription3] = useState(false);
  const [_activateDescription4, _setActivateDescription4] = useState(false);
  const [activateDescription5, setActivateDescription5] = useState(false);
  const [commitmentSection, setCommitmentSection] = useState(false);
  const [showDescription, setShowDescription] = useState(0);
  const [moreInformation, setMoreInformation] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState(themeList[0]);
  const [text, setText] = useState('info@bene-regalo.com');
  const [cart, setCart] = useRecoilState(LPCartState);
  const totalPrice = useRecoilValue(LPtotalPriceState);
  const totalCount = useRecoilValue(LPtotalCountState);
  const setBanner = useSetRecoilState(bannerState);
  const firstView = useRecoilValue(firstViewState);
  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  // theme swiper関連
  let timeoutID = 0;
  let startX = 0;
  let startY = 0;
  let swipeCount = 0;
  let rotate = 0;
  const slideNum = 10;
  // line login関連
  const [_lineUser, setLineUser] = useState([
    {
      name: 'ゲストユーザー',
      picture: ``,
      // : '',
    },
  ]);
  const url = 'https://api.line.me/oauth2/v2.1/token';
  const url2 = 'https://api.line.me/oauth2/v2.1/verify';
  const option = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      code: `${query2.get('code')}`,
      redirect_uri: `${encodeURI(
        `${
          process.env.REACT_APP_IS_PROD === 'true'
            ? 'https://bene-regalo.com/'
            : 'https://bene-reglo-lp--preview-azgxuhem.web.app/'
        }`
      )}`,
      client_id: '1657393544',
      client_secret: 'c6e1814583b38a1a984ccc4262c59988',
    }),
  };

  // #endregion Variables

  // #region Functions

  const scrollToBottomOfList = useCallback(() => {
    const bannerElement = document.getElementById('topItems');
    bannerElement.scrollIntoView([true | { behavior: 'smooth' }]);
  }, [ref]);

  const handleScroll = () => {
    const footer = document.getElementById('footer');
    setFooterPanelHeight(footer?.clientHeight);
    setShopPageLink(true);
  };

  //店舗一覧ボタンのクリック
  const shopIconClicked = () => {
    setShopList(!shopList);
    updateBanner(true);
  };

  const updateTermsAndPolicy = (key, value) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };

  const moveWindow = () => {
    scrollToBottomOfList();
    setShow(false);
  };

  // カートの数量変更
  const addToCart = (key, count) => {
    setCart((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['items'][key]['count'] = count;
      return future;
    });
  };
  // カートモーダルの開閉
  const setShow = (boolean) => {
    setCart((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['show'] = boolean;
      return future;
    });
  };

  // MEMO: LINEログインのコールバック後の処理
  const getToken = async () => {
    await fetch(url, option)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.id_token !== undefined) validateToken(result.id_token);
      })
      .catch((_error) => {
        // console.log(error);
      });
  };
  const validateToken = async (idToken) => {
    await fetch(url2, {
      method: 'POST',
      body: new URLSearchParams({
        id_token: `${idToken}`,
        client_id: '1657393544',
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setLineUser([result]);
        sendLineId(result);
      })
      .catch((_error) => {
        // console.log(error);
      });
  };

  // line loginの情報をfirestoreに保存
  const sendLineId = async (user) => {
    const today = new Date();
    const LineIdRef = doc(db, 'line_ids', `${user.sub}`);
    await setDoc(LineIdRef, { user });
    const OrderIdRef = doc(db, 'line_ids', `${user.sub}`, 'orders', query2.get('state'));
    await setDoc(OrderIdRef, { date: today });
  };

  const scrollToProfile = () => {
    const profileWrap = document.getElementById('profileWrap');
    const clientRect = profileWrap.getBoundingClientRect();
    window.scroll({
      top: window.pageYOffset + clientRect.top,
      behavior: 'smooth',
    });
  };

  const scrollToFeatured = () => {
    const bannerElement = document.getElementById('featured');
    bannerElement.scrollIntoView([true | { behavior: 'smooth' }]);
  };

  const updateBanner = (boolean) => {
    setBanner((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future.shopClicked = boolean;
      return future;
    });
  };

  const changeTheme = (theme) => {
    const newTheme = themeList[theme.key % themeList.length];
    setTheme(newTheme);
  };

  const changeThemeBack = (theme) => {
    const newTheme = themeList[(theme.key + themeList.length - 2) % themeList.length];
    setTheme(newTheme);
  };

  const touchStart = (event) => {
    startX = event.touches[0].pageX;
    startY = event.touches[0].pageY;
  };

  const touchMove = (event) => {
    const delay = 100;
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      const swipeX = event.changedTouches[0].pageX - startX;
      const swipeY = event.changedTouches[0].pageY - startY;

      if (Math.abs(swipeX) > Math.abs(swipeY) && swipeX > 50) {
        swipeLeft();
      } else if (Math.abs(swipeX) > Math.abs(swipeY) && swipeX < -50) {
        swipeRight();
      }
    }, delay);
  };

  const swipeRight = () => {
    changeTheme(themeList[swipeCount]);
    swipeCount = (swipeCount + 1) % themeList.length;
    rotate += 360 / slideNum;
    document.documentElement.style.setProperty('--rotate', `${rotate}deg`);
  };
  const swipeLeft = () => {
    changeThemeBack(themeList[swipeCount]);
    swipeCount = (swipeCount - 1 + themeList.length) % themeList.length;
    rotate -= 360 / slideNum;
    document.documentElement.style.setProperty('--rotate', `${rotate}deg`);
  };

  const swipeToSpecificSlide = (themeKey) => {
    const dif = themeKey - swipeCount - 1;
    if (dif >= 0) {
      for (let i = 0; i < dif; i++) {
        swipeRight();
      }
    } else {
      for (let i = 0; i < dif * -1; i++) {
        swipeLeft();
      }
    }
  };

  const swiper = (name) => {
    const lst = [];

    for (let j = 0; j < slideNum / themeList.length; j++) {
      for (let i = 0; i < themeList.length; i++) {
        const data = themeList[i];
        const elements = (
          <div
            key={i}
            className={classNames(
              css.circle,
              css['circle' + String(data.key + themeList.length * j)],
              css['circle' + name]
            )}
          >
            <picture>
              <source srcSet={`${process.env.PUBLIC_URL}/top/${data.img001}.avif`} type="image/avif" />
              <img
                src={`${process.env.PUBLIC_URL}/top/${data.img001}.webp`}
                alt=""
                className={classNames(
                  css['swipeImg' + data.name],
                  data.key === theme.key
                    ? css.centerSlide
                    : data.key === (theme.key % themeList.length) + 1
                    ? css.nextSlide
                    : data.key === ((theme.key + themeList.length - 2) % themeList.length) + 1
                    ? css.prevSlide
                    : css.backSlide,
                  css.swipeImg
                )}
              />
            </picture>
          </div>
        );
        if (i + themeList.length * j < slideNum) {
          lst[i + themeList.length * j] = elements;
        }
      }
    }

    return lst;
  };

  function MatchaLp(props) {
    const settings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      swipe: false,
      pauseOnHover: false,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const images = [`nadaru1.webp`, `nadaru2.webp`, `nadaru3.webp`, `nadaru4.webp`];
    if (props.theme === 'Matcha') {
      return (
        <div className={css.matchaLp}>
          <div className={css.headerImgBox}>
            <img src={`${process.env.PUBLIC_URL}/top/nadaru.webp`} />
          </div>
          <div className={css.middleContent}>
            <p className={classNames(css.title, css.fontTaFugaFude)}>
              コロコロチキチキペッパーズ <br className={css.indent} />
              ナダルさん
            </p>
            <p className={classNames(css.body, css.fontTaFugaFude)}>本気で開発</p>
          </div>

          <div className={css.swipe}>
            <Slider {...settings}>
              {images.map((img, key) => {
                return (
                  <div key={key} className={css.nadaruImages}>
                    <img
                      style={{ width: '80%', maxHeight: 'auto', paddingLeft: '10px', paddingRight: '10px' }}
                      src={`${process.env.PUBLIC_URL}/top/${img}`}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>

          <div>
            <iframe
              style={{ minWidth: '320px', width: '80vw', maxWidth: '789px', aspectRatio: ' 16 / 9' }}
              src="https://www.youtube.com/embed/GFLUxEcwxEo"
              title="🍵『まっちゃってる！抹茶てぃらみすぷりん』コロチキナダル×BENE REGALO🍰夢のコラボ商品がついに完成！"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      );
    }
    return null;
  }

  const updateText = (text) => {
    setText(text);
  };

  const notify = () => toast.success('メールアドレスをコピーしました');

  // #endregion Functions

  // #region UseEffect

  useEffect(() => {
    //購入制限
    const uids = ['uid1', 'uid2']; //対象の商品
    for (const uid of uids) {
      if (cart['items'][uid]['count'] >= cart['items'][uid]['limit'] && cart['items'][uid]['limit'] !== null) {
        if (cart['items'][uid]['disabled'] !== true) {
          setCart((current) => {
            const future = JSON.parse(JSON.stringify(current));
            future['items'][uid]['disabled'] = true;
            return future;
          });
        }
      } else {
        if (cart['items'][uid]['disabled'] !== false) {
          setCart((current) => {
            const future = JSON.parse(JSON.stringify(current));
            future['items'][uid]['disabled'] = false;
            return future;
          });
        }
      }
    }
  }, [cart, setCart]);

  useEffect(() => {
    //セット購入のみ
    const uids = ['uid3', 'uid5', 'uid6']; //対象商品
    const total = uids
      .map((uid) => cart['items'][uid]['price'] * cart['items'][uid]['count'])
      .reduce(function (sum, element) {
        return sum + element;
      }, 0);
    const priceWithout = sumUpCart(cart) - total;
    for (const uid of uids) {
      if (sumUpCart(cart) > 0 && cart['items'][uid]['disabled'] === true) {
        setCart((current) => {
          const future = JSON.parse(JSON.stringify(current));
          future['items'][uid]['disabled'] = false;
          return future;
        });
      }
      if (priceWithout === 0 && cart['items'][uid]['disabled'] === false) {
        setCart((current) => {
          const future = JSON.parse(JSON.stringify(current));
          future['items'][uid]['disabled'] = true;
          future['items'][uid]['count'] = 0;
          return future;
        });
      }
    }
  }, [cart, setCart]);

  useEffect(() => {
    // first viewの高さを設定
    document.documentElement.style.setProperty('--outer-height', `${window.innerHeight}px`);
    if (firstView.visited) {
      window.addEventListener('scroll', handleScroll);
    }

    // cookie関連の処理？
    setReferrerData(window.location.href);
    sendRefererData('Home');
    updateTermsAndPolicy('backPage', '/');

    // line login 処理
    if (query2.get('code') !== null) {
      getToken();
    }

    // theme swiper の設定
    document.documentElement.style.setProperty('--rotate', `0deg`);
    const isTouchable = 'ontouchstart' in window || (window.DocumentTouch && document);
    document.getElementById('rightNavigator').addEventListener('click', () => swipeRight());
    document.getElementById('leftNavigator').addEventListener('click', () => swipeLeft());
    document.getElementById('rightNavigator2').addEventListener('click', () => {
      swipeRight();
      const element = document.getElementById('swipeArea');
      element.scrollIntoView();
    });
    document.getElementById('leftNavigator2').addEventListener('click', () => {
      swipeLeft();
      const element = document.getElementById('swipeArea');
      element.scrollIntoView();
    });
    if (isTouchable) {
      // SP
      document.getElementById('swipeArea').addEventListener('touchstart', (e) => touchStart(e));
      document.getElementById('swipeArea').addEventListener('touchmove', (e) => touchMove(e));

      document.getElementById('swipeArea2').addEventListener('touchstart', (e) => touchStart(e));
      document.getElementById('swipeArea2').addEventListener('touchmove', (e) => touchMove(e));
    } else {
      // PC
      document.getElementById('themeTitle').addEventListener('click', () => swipeRight());
      document.getElementById('themeTitle2').addEventListener('click', () => swipeRight());
    }
  }, []);

  // #endregion UseEffect

  // #region Render

  window.onload = function () {
    // このlogは環境を把握するために残しておく
    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
      // eslint-disable-next-line no-console
      console.log(`${process.env.REACT_APP_ENVIRONMENT}環境`);
    }
  };

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  // #endregion Render

  return (
    <div>
      <div className={classNames(css.Home, css['Home' + theme.name])}>
        <FirstView
          handleScroll={handleScroll}
          updateBanner={updateBanner}
          scrollToFeatured={scrollToFeatured}
          swipeToSpecificSlide={swipeToSpecificSlide}
        />
        <div className={shopPageLink ? css.shopPageLink : css.displayNone}>
          <div className={css.shopButtonCover} onClick={shopIconClicked}></div>
          <div className={css.shopButton} onClick={shopIconClicked}>
            <img className={css.shopIcon} src={`${process.env.PUBLIC_URL}/shopIcon.webp`} alt="" />
            <div className={css.buttonText}>店舗一覧</div>
          </div>
          <Link to={'/akigawa'} className={shopList ? css.linkToAkigawa : css.displayNone}>
            秋川本店
          </Link>
          <Link to={'/yoyogi'} className={shopList ? css.linkToYoyogi : css.displayNone}>
            代々木店
          </Link>
        </div>
        <div className={css.txAC}>
          {
            // itemPanelHeight > footerHeight
            true && (
              <div className={css.homeCartSection} id="textTop">
                <img
                  src={`${process.env.PUBLIC_URL}/CartLogo.svg`}
                  alt=""
                  className={totalCount > 0 ? css.displayNone : css.cartImg}
                  onClick={() => {
                    setShow(true);
                  }}
                />
                <div
                  onClick={() => {
                    setShow(true);
                  }}
                  className={totalCount > 0 ? '' : css.displayNone}
                >
                  <TotalPrice>
                    <CartIconCon>
                      <img
                        className={classNames(css.vertAlM, css.cart_icon)}
                        src={`${process.env.PUBLIC_URL}/shopping_cart.svg`}
                        alt=""
                      />
                      <CountIcon>{totalCount}</CountIcon>
                    </CartIconCon>
                    <PriceCon>
                      <PriceFonts>
                        <span className={`${css.priceFont}`}>合計</span>
                        <span className={`${css.priceFont2}`}>¥</span>
                        <span className={`${css.priceFont3} ${css.fw_b}`}>{totalPrice.toLocaleString()}</span>
                        <span className={`${css.priceFont}`}>(税込)</span>
                      </PriceFonts>
                    </PriceCon>
                  </TotalPrice>
                </div>
              </div>
            )
          }
          <div className={css.firstSection}>
            <div className={`${css.titleText}`}>ティラミスを愛する人へ</div>
            <picture>
              <source srcSet={`${process.env.PUBLIC_URL}/top/top_illustration1.avif`} type="image/avif" />
              <img src={`${process.env.PUBLIC_URL}/top/top_illustration1.webp`} alt="" className={css.illust1} />
            </picture>
            <picture>
              <source srcSet={`${process.env.PUBLIC_URL}/top/top_illustration2.avif`} type="image/avif" />
              <img src={`${process.env.PUBLIC_URL}/top/top_illustration2.webp`} alt="" className={css.illust2} />
            </picture>
            <div className={`${css.titleText}`}>甘さ控えめな甘え先</div>
            <p className={css.mb40}>
              濃厚なクリーム
              <br />
              深みのあるエスプレッソ
              <br />
              最高のティラミスは
              <br />
              甘さを抑えた大人の味
            </p>
            <p className={css.mb40}>
              たまには一息
              <br />
              美味しいもので
              <br />
              何かに甘えてみませんか
            </p>
          </div>
        </div>
        <div className={css.txAC}>
          <div id="swipeArea">
            <div className={css.swipeArea}>
              {/* {swiper("down")} */}
              {swiper('up')}
              <div id="leftNavigator" className={css.leftNavigator}></div>
              <div id="rightNavigator" className={css.rightNavigator}></div>
            </div>
            <br />
            <div id="themeTitle" className={classNames(css.primo, css['primo' + theme.name])}>
              <span>{theme.title001}</span>
            </div>
          </div>
          {
            // 現在はご予約を受け付けていません（白黒）
            [2, 3].includes(theme.key) ? (
              <div className={css.notAvailable}>
                <br />
                ※現在、ご予約受付期間外です。
                <br />
                次回は
                <br className={css.indent} />
                <span className={css.underLine}>2024年9月</span>
                を<br className={css.indent} />
                予定しております。
              </div>
            ) : (
              ''
            )
          }
          <MatchaLp theme={theme.name} />
          <div className={classNames(css.titleText, css['titleText' + theme.name])}>{theme.title002}</div>

          {/* 画像セクション1 */}
          <ImageSection theme={theme} section={theme.imageSection001} orientation="Left" />

          {
            // ティラミスこだわりセクション Primoのみ
            theme.key === 1 ? (
              <div className={css.commitmentSection}>
                <div className={css.commitmentSectionTitle}>
                  Primo Tiramisu<span className={css.fontJp}>のこだわり</span>
                </div>
                <div className={css.imgWrap}>
                  <div
                    className={css.spreadButton}
                    onClick={() => {
                      setCommitmentSection(true);
                    }}
                  ></div>
                  <img
                    src={`${process.env.PUBLIC_URL}/top/magnifying_glass.svg`}
                    alt=""
                    className={commitmentSection ? css.spreadAnimation : css.loopAnimation}
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/top/primo_tiramisu.webp`}
                    alt=""
                    className={commitmentSection ? css.tiramisuIcon : css.tiramisuMiniIcon}
                  />
                </div>
                <div className={commitmentSection ? css.profileWrap : css.displayNone} id="profileWrap">
                  <img
                    src={`${process.env.PUBLIC_URL}/top/tiramisu_profile.webp`}
                    alt=""
                    className={commitmentSection ? css.tiramisuProfileImg : ''}
                  />
                  <div className={css.materials}>
                    {/* <div
                            className={classNames(css.callOutLine1, css.callOutLine)}
                          ></div> */}
                    <div className={classNames(css.callOutLine2, css.callOutLine)}></div>
                    <div className={classNames(css.callOutLine3, css.callOutLine)}></div>
                    <div className={classNames(css.callOutLine4, css.callOutLine)}></div>
                    <div className={classNames(css.callOutLine5, css.callOutLine)}></div>
                    {/* <div
                            className={classNames(css.material1, css.material)}
                            onClick={() => {
                              setShowDescription(1);
                              setMoreInformation(false);
                            }}
                          >
                            ヴァローナカカオ
                          </div> */}
                    <div
                      className={classNames(css.material2, css.material)}
                      onClick={() => {
                        setShowDescription(2);
                        setMoreInformation(false);
                      }}
                    >
                      ティラミスクリーム
                    </div>
                    <div
                      className={classNames(css.material3, css.material)}
                      onClick={() => {
                        setShowDescription(3);
                        setMoreInformation(false);
                      }}
                    >
                      ガナッシュ
                    </div>
                    <div
                      className={classNames(css.material4, css.material)}
                      onClick={() => {
                        setShowDescription(4);
                        setMoreInformation(false);
                      }}
                    >
                      ビスキュイジョコンド
                    </div>
                    <div
                      className={classNames(css.material5, css.material)}
                      onClick={() => {
                        setShowDescription(5);
                        setMoreInformation(false);
                      }}
                    >
                      エスプレッソシロップ
                    </div>
                  </div>
                </div>
                {/* <div
                        className={classNames(
                          css.description,
                          showDescription === 1 ? "" : css.displayNone
                        )}
                      >
                        <div className={css.descriptionTitle}>ヴァローナカカオ</div>
                      </div> */}
                <div className={classNames(css.description, showDescription === 2 ? '' : css.displayNone)}>
                  <div className={css.descriptionTitle}>ティラミスクリーム</div>
                  <picture>
                    <source srcSet={`${process.env.PUBLIC_URL}/top/tiramisu_cream.avif`} type="image/avif" />
                    <img
                      src={`${process.env.PUBLIC_URL}/top/tiramisu_cream.webp`}
                      alt=""
                      className={classNames(css.mb40, css.wid100)}
                    />
                  </picture>
                  <span className={css.bold}>イタリア産マスカルポーネチーズと北海道産クリームチーズ</span>
                  を使った濃厚さ、<span className={css.bold}>卵黄</span>
                  をふんだんに使用した
                  <span className={css.bold}>ザバイオーネ</span>
                  （※）の深いコク、
                  <span className={css.bold}>マルサラワイン</span>
                  の爽やかで芳醇な香り、これらの要素を調和させることで
                  <span className={css.bold}>ティラミスの要である最高のクリーム</span>
                  を実現させました。
                  <br />
                  <br />
                  <div
                    className={moreInformation ? css.displayNone : css.moreInformation}
                    onClick={() => {
                      setMoreInformation(true);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>ザバイオーネとは？</span>
                    </div>
                    <div className={css.bold}>＋</div>
                  </div>
                  <div
                    className={moreInformation ? css.moreInformation : css.displayNone}
                    onClick={() => {
                      setMoreInformation(false);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>ザバイオーネとは？</span>
                    </div>
                    <div className={css.bold}>－</div>
                  </div>
                  <div className={moreInformation ? '' : css.displayNone}>
                    <br />
                    イタリア発祥のムース状のクリームで、主な材料は卵、砂糖、マルサラ酒で、砂糖を加えるか、塩を加えるかにより、スイーツにも料理にも使用される。
                  </div>
                  <br />
                  BENE
                  REGALOのザバイオーネは、卵黄をホイッパーで空気を入れるようによく泡立てながら火を入れることで口当たりのなめらかなムースに仕上げています。さらにザバイオーネと生クリームをふんわりと泡立ててからチーズクリームに混ぜ合わせることで、口に入れた瞬間にとろけてしまうような口当たりになります。
                </div>
                <div className={classNames(css.description, showDescription === 3 ? '' : css.displayNone)}>
                  <div className={css.descriptionTitle}>ガナッシュ</div>
                  <picture>
                    <source srcSet={`${process.env.PUBLIC_URL}/top/ganache.avif`} type="image/avif" />
                    <img
                      src={`${process.env.PUBLIC_URL}/top/ganache.webp`}
                      alt=""
                      className={classNames(css.mb40, css.wid100)}
                    />
                  </picture>
                  ティラミスクリームとエスプレッソソースの離れた味を調和させるためにチョコレートを
                  <span className={css.bold}>ガナッシュ</span>
                  にして
                  <span className={css.bold}>クリームと生地の間に忍ばせました</span>。
                  <span className={css.bold}>カカオのまろやかな旨味</span>
                  がマスカルポーネチーズとエスプレッソの風味を優しく包みこむことで、
                  <span className={css.bold}>チーズとコーヒーという離れた二つの味を ”ティラミス”へと昇華させます</span>
                  。また、コーヒーの苦味だけでなく、
                  <span className={css.bold}>ショコラの苦味や風味</span>
                  を加えることでコーヒーの苦味をマイルドに打ち消しつつ
                  <span className={css.bold}>味に奥行き</span>を持たせています。
                  <br />
                  <br />
                  <div
                    className={moreInformation ? css.displayNone : css.moreInformation}
                    onClick={() => {
                      setMoreInformation(true);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>ガナッシュとは？</span>
                    </div>
                    <div className={css.bold}>＋</div>
                  </div>
                  <div
                    className={moreInformation ? css.moreInformation : css.displayNone}
                    onClick={() => {
                      setMoreInformation(false);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>ガナッシュとは？</span>
                    </div>
                    <div className={css.bold}>－</div>
                  </div>
                  <div className={moreInformation ? '' : css.displayNone}>
                    <br />
                    チョコレートと生クリームをまぜ合わせてできる。とろけるような口溶けと濃厚な甘味が特徴で、ほとんどそのまま食べるものとしては生チョコが挙げられる。
                  </div>
                  {/* <br /> */}
                  <br />
                  粒のチョコレート等ではなくガナッシュにすることでクリームとスポンジの間を上手に繋ぎ止め、よりなめらかで濃厚な口当たりを実現しました。また、チョコレート単体だとショコラの風味が強くなってしまうため、カカオ分を調整したクーベルチュールを使用し、ティラミスに合うまろやかな風味に仕上げました。
                </div>
                <div className={classNames(css.description, showDescription === 4 ? '' : css.displayNone)}>
                  <div className={css.descriptionTitle}>ビスキュイジョコンド</div>
                  <picture>
                    <source srcSet={`${process.env.PUBLIC_URL}/top/biscuit_joconde.avif`} type="image/avif" />
                    <img
                      src={`${process.env.PUBLIC_URL}/top/biscuit_joconde.webp`}
                      alt=""
                      className={classNames(css.mb40, css.wid100)}
                    />
                  </picture>
                  <span className={css.bold}>ふんわりとした軽い食感</span>
                  に焼き上げたBENE REGALOのビスキュイには
                  <span className={css.bold}>アーモンドの旨味</span>
                  を加えました。
                  <span className={css.bold}>ビスキュイジョコンド</span>
                  にすることで、エスプレッソをふんだんに染み込ませても、
                  <span className={css.bold}>形崩れをせずティラミスクリームを支え</span>
                  てくれます。
                  <br />
                  卵白と卵黄を別々に泡立てる
                  <span className={css.bold}>「別立て」</span>
                  という方法で作られるため、空気を多く含み、焼き上がると
                  <span className={css.bold}>外側がカリッ</span>としながらも、
                  <span className={css.bold}>ふんわり</span>としていて、
                  <span className={css.bold}>口の中で溶ける</span>
                  様な食感が特徴です。
                  <br />
                  <br />
                  ※BENE
                  REGALOは手作りのため、アーモンド等アレルギーがある方に関しては、アレルギー原料を使用せずに製造することも可能です。お気軽にお申し付けください。
                  <br />
                  <br />
                  <div
                    className={moreInformation ? css.displayNone : css.moreInformation}
                    onClick={() => {
                      setMoreInformation(true);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>ビスキュイジョコンドとは？</span>
                    </div>
                    <div className={css.bold}>＋</div>
                  </div>
                  <div
                    className={moreInformation ? css.moreInformation : css.displayNone}
                    onClick={() => {
                      setMoreInformation(false);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>ビスキュイジョコンドとは？</span>
                    </div>
                    <div className={css.bold}>－</div>
                  </div>
                  <div className={moreInformation ? '' : css.displayNone}>
                    <br />
                    小麦粉や卵にアーモンドパウダーと同量の粉砂糖を合わせて空気を含ませ、さらにメレンゲとバターを加えて高温で焼き上げた生地のことを指す。
                  </div>
                  <br />
                  たっぷりのアーモンドが入っている生地は、しっとりとして風味も豊かになり、卵黄のベースにもしっかり空気を含ませることなどで、他の生地にはない適度な弾力と骨格が生まれます。
                </div>
                <div className={classNames(css.description, showDescription === 5 ? '' : css.displayNone)}>
                  <div className={css.descriptionTitle}>エスプレッソシロップ</div>
                  <picture>
                    <source srcSet={`${process.env.PUBLIC_URL}/top/espresso_syrup.avif`} type="image/avif" />
                    <img
                      src={`${process.env.PUBLIC_URL}/top/espresso_syrup.webp`}
                      alt=""
                      className={classNames(css.mb40, css.wid100)}
                    />
                  </picture>
                  <span className={css.bold}>厳選されたアラビカ種豆100%</span>
                  を使用し、チョコレートやトーストのような香り深く、
                  <span className={css.bold}>力強いコクのある濃厚な味わい</span>
                  は、アーモンドの旨味が加わったビスキュイと調和します。コーヒーが苦手な人でも楽しめるように砂糖の量を調節することで
                  <span className={css.bold}>苦味を緩和</span>し、
                  <span className={css.bold}>味の深み</span>
                  だけを活かし、さらにコーヒーを混ぜ合わせることで冷凍解凍した後でも
                  <span className={css.bold}>香りが立つ</span>ように工夫しました。
                  <br />
                  <br />
                  <div
                    className={moreInformation ? css.displayNone : css.moreInformation}
                    onClick={() => {
                      setMoreInformation(true);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>エスプレッソとは？</span>
                    </div>
                    <div className={css.bold}>＋</div>
                  </div>
                  <div
                    className={moreInformation ? css.moreInformation : css.displayNone}
                    onClick={() => {
                      setMoreInformation(false);
                    }}
                  >
                    <div>
                      ※<span className={css.bold}>エスプレッソとは？</span>
                    </div>
                    <div className={css.bold}>－</div>
                  </div>
                  <div className={moreInformation ? '' : css.displayNone}>
                    <br />
                    コーヒー豆を挽いたコーヒー粉をマシンへセットし、高い圧力をかけてコーヒーを抽出する方法とそのコーヒーのこと。
                  </div>
                  <br />
                  コーヒーが苦手な人でも楽しめるように砂糖の量を調節することで苦味を緩和し、味の深みだけを活かし、さらにコーヒーを混ぜ合わせることで冷凍解凍した後でも香りが立つように工夫しました。
                </div>
                <div
                  className={
                    commitmentSection && 1 <= showDescription && showDescription <= 5
                      ? css.profileWrap2
                      : css.displayNone
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/top/tiramisu_profile.webp`}
                    alt=""
                    className={commitmentSection ? css.tiramisuProfileImg : ''}
                  />
                  <div className={css.materials}>
                    {/* <div
                            className={classNames(css.callOutLine1, css.callOutLine)}
                          ></div> */}
                    <div className={classNames(css.callOutLine2, css.callOutLine)}></div>
                    <div className={classNames(css.callOutLine3, css.callOutLine)}></div>
                    <div className={classNames(css.callOutLine4, css.callOutLine)}></div>
                    <div className={classNames(css.callOutLine5, css.callOutLine)}></div>
                    {/* <div
                            className={classNames(css.material1, css.material)}
                            onClick={() => {
                              setShowDescription(1);
                              setMoreInformation(false);
                              scrollToProfile();
                            }}
                          >
                            ヴァローナカカオ
                          </div> */}
                    <div
                      className={classNames(css.material2, css.material)}
                      onClick={() => {
                        setShowDescription(2);
                        setMoreInformation(false);
                        scrollToProfile();
                      }}
                    >
                      ティラミスクリーム
                    </div>
                    <div
                      className={classNames(css.material3, css.material)}
                      onClick={() => {
                        setShowDescription(3);
                        setMoreInformation(false);
                        scrollToProfile();
                      }}
                    >
                      ガナッシュ
                    </div>
                    <div
                      className={classNames(css.material4, css.material)}
                      onClick={() => {
                        setShowDescription(4);
                        setMoreInformation(false);
                        scrollToProfile();
                      }}
                    >
                      ビスキュイジョコンド
                    </div>
                    <div
                      className={classNames(css.material5, css.material)}
                      onClick={() => {
                        setShowDescription(5);
                        setMoreInformation(false);
                        scrollToProfile();
                      }}
                    >
                      エスプレッソシロップ
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          }

          {
            // タイトル
            [1, 2, 3, 4, 5, 6, 9].includes(theme.key) ? (
              <div className={classNames(css.thirdSection, css['thirdSection' + theme.name])}>{theme?.title003}</div>
            ) : (
              ''
            )
          }

          {
            // 画像セクション2
            [1, 2, 3, 4, 5, 6, 9].includes(theme.key) ? (
              <ImageSection theme={theme} section={theme.imageSection002} orientation="Right" />
            ) : (
              ''
            )
          }

          {
            // 画像セクション3
            [1, 2, 3].includes(theme.key) ? (
              <StyledDiv Responsive={{ 'margin-top': [64, 80, 80] }}>
                <ImageSection theme={theme} section={theme.imageSection003} orientation="Left" />
              </StyledDiv>
            ) : (
              ''
            )
          }

          {
            // 食べ方セクション
            [1, 2, 3].includes(theme.key) ? (
              <>
                <div className={classNames(css.eatText, css['eatText' + theme.name])}>{theme.text010}</div>
                <div className={classNames(css.eatSection)}>
                  <div className={classNames(css.descFlex)}>
                    <div className={css.desc}>
                      <div className={classNames(css.index, css['index' + theme.name])}>1</div>
                      <div className={classNames(css.title, css['title' + theme.name])}>{theme.text011}</div>
                      <picture>
                        <source srcSet={`${process.env.PUBLIC_URL}/top/${theme.img005}.avif`} type="image/avif" />
                        <img src={`${process.env.PUBLIC_URL}/top/${theme.img005}.webp`} alt="" className={css.Img3} />
                      </picture>
                      <div className={classNames(css.text, css['text' + theme.name])}>{theme.text012}</div>
                    </div>
                    <div className={classNames(css.desc, css.margin)}>
                      <div className={classNames(css.index, css['index' + theme.name])}>2</div>
                      <div className={classNames(css.title, css['title' + theme.name])}>{theme.text013}</div>
                      <picture>
                        <source srcSet={`${process.env.PUBLIC_URL}/top/${theme.img006}.avif`} type="image/avif" />
                        <img src={`${process.env.PUBLIC_URL}/top/${theme.img006}.webp`} alt="" className={css.Img5} />
                      </picture>
                      <div className={classNames(css.text, css['text' + theme.name])}>{theme.text014}</div>
                    </div>
                  </div>
                  <div className={classNames(css.descFlex, css.padding)}>
                    <div className={css.desc}>
                      <div className={classNames(css.index, css['index' + theme.name])}>3</div>
                      <div className={classNames(css.title, css['title' + theme.name])}>{theme.text015}</div>
                      <picture>
                        <source srcSet={`${process.env.PUBLIC_URL}/top/${theme.img007}.avif`} type="image/avif" />
                        <img src={`${process.env.PUBLIC_URL}/top/${theme.img007}.webp`} alt="" className={css.Img4} />
                      </picture>
                      <div className={classNames(css.text, css['text' + theme.name])}>{theme.text016}</div>
                    </div>
                    <div className={classNames(css.desc, css.margin)}>
                      <div className={classNames(css.index, css['index' + theme.name])}>4</div>
                      <div className={classNames(css.title, css['title' + theme.name])}>{theme.text017}</div>
                      <picture>
                        <source srcSet={`${process.env.PUBLIC_URL}/top/${theme.img008}.avif`} type="image/avif" />
                        <img src={`${process.env.PUBLIC_URL}/top/${theme.img008}.webp`} alt="" className={css.Img6} />
                      </picture>
                      <div className={classNames(css.text, css['text' + theme.name])}>{theme.text018}</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )
          }

          <div id="swipeArea2">
            <div className={css.swipeArea}>
              {/* {swiper("down")} */}
              {swiper('up')}
              <div id="leftNavigator2" className={css.leftNavigator}></div>
              <div id="rightNavigator2" className={css.rightNavigator}></div>
            </div>

            <br />

            <div
              id="themeTitle2"
              className={classNames(css.primo, css['primo' + theme.name])}
              onClick={() => {
                // changeTheme(theme);
                // swipeRight();
              }}
            >
              <span>{theme.title001}</span>
            </div>
          </div>
          {
            // 現在はご予約を受け付けていません（白黒）
            [2, 3].includes(theme.key) ? (
              <div className={css.notAvailable}>
                <br />
                ※現在、ご予約受付期間外です。
                <br />
                次回は
                <br className={css.indent} />
                <span className={css.underLine}>2024年9月</span>
                を<br className={css.indent} />
                予定しております。
              </div>
            ) : (
              ''
            )
          }
        </div>

        <section className={css.mediaSection}>
          <h2>メディア出演実績</h2>

          <Swiper
            loop={true}
            // MEMO : Warningが出るので一時的に無効かしています
            // effectFade={true}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            speed={800}
            slidesPerView={1}
            centeredSlides={true}
            className={css.mediaSlide}
            modules={[EffectFade, Pagination, Autoplay]}
            spaceBetween={24}
          >
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2022/10/26 <br className={css.changeLine} />
                <span className={css.fontJp}>ヒルナンデス!!放送</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection1.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2023/07/22 <br className={css.changeLine} />
                <span className={css.fontJp}>ガッツ100%TV出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection2.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2022/06/28 <br className={css.changeLine} />
                <span className={css.fontJp}>どさんこワイド出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection3.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2022/06/28 <br className={css.changeLine} />
                <span className={css.fontJp}>今日ドキッ！出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection4.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2023/10/14 <br className={css.changeLine} />
                <span className={css.fontJp}>柏原芳恵の喫茶☆歌謡界出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection5.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2023/10/21 <br className={css.changeLine} />
                <span className={css.fontJp}>コロチキナダルさんコラボ商品</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection6.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2023/11/05 <br className={css.changeLine} />
                <span className={css.fontJp}>なりゆき街道旅出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection7.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2023/11/05 <br className={css.changeLine} />
                <span className={css.fontJp}>なりゆき街道旅出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection8.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/01/31 <br className={css.changeLine} />
                <span className={css.fontJp}>めざましテレビ イマドキ出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection9.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/01/31 <br className={css.changeLine} />
                <span className={css.fontJp}>めざましテレビ イマドキ出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection10.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/04/03 <br className={css.changeLine} />
                <span className={css.fontJp}>かまいたちの掟出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection11.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/04/03 <br className={css.changeLine} />
                <span className={css.fontJp}>かまいたちの掟出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection12.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/04/03 <br className={css.changeLine} />
                <span className={css.fontJp}>かまいたちの掟出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection13.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/04/03 <br className={css.changeLine} />
                <span className={css.fontJp}>かまいたちの掟出演</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection14.webp`} />
            </SwiperSlide>
            <SwiperSlide>
              <h3 className={css.mediaTitle}>
                2024/06
                <br className={css.changeLine} />
                <span className={css.fontJp}>かまいたちコラボティラミス発売</span>
              </h3>
              <img src={`${process.env.PUBLIC_URL}/top/mediaSection15.webp`} />
            </SwiperSlide>
          </Swiper>
          <div className={css.mediaButtons}>
            <button
              className={css.cooperateButton}
              onClick={() => window.open('https://locagoo.co.jp/location/eight-good/', 'blank_')}
            >
              ご協力できること
            </button>

            <div>
              <button className={css.mediaLinkButton} onClick={() => setIsOpen(true)}>
                メディア用連絡先
              </button>
              <Modal
                style={{
                  overlay: {
                    zIndex: 100,
                  },
                  content: {
                    border: 'none',
                  },
                }}
                className={css.mediaModal}
                isOpen={modalIsOpen}
              >
                <div className={css.mediaOverlay}>
                  <div className={css.mediaModalContent}>
                    <p>
                      <button className={css.mediaCloseButton} onClick={() => setIsOpen(false)}>
                        <MdClose />
                      </button>
                    </p>

                    <div className={css.mailCopyContainer}>
                      <div className={css.mailClipped}>
                        <input type={text} onChange={(e) => updateText(e.target.value)} value={text} />
                      </div>
                      <div className={css.mailAddress}>info＠bene-regalo.com</div>
                      <div>
                        <CopyToClipboard text={text}>
                          <div>
                            <button onClick={notify} className={css.modalCopyButton}>
                              コピー
                            </button>
                            <Toaster position="top-right" reverseOrder={false} />
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </section>
        <div>
          {/* 白黒ティラミス
          // MEMO: 白黒復活のため残す
          */}
          {/* <div ref={ref} className={css.goodsSection}>
            <div className={classNames(css.itemPanels)}>
              <ItemPanel
                cart={cart}
                uid="uid16"
                addToCart={addToCart}
                setShow={setShow}
                annotate={false}
                description="クール便 +1000円"
                descriptionStyle="refrigeration"
              />
              <ItemPanel
                cart={cart}
                uid="uid17"
                addToCart={addToCart}
                setShow={setShow}
                annotate={false}
                description="クール便 +1000円"
                descriptionStyle="refrigeration"
              />
            </div>
            <div className={css.itemPanels}>
              <ItemPanel
                cart={cart}
                uid="uid24"
                addToCart={addToCart}
                setShow={setShow}
                annotate={false}
                description="送料無料"
                descriptionStyle="freeShipping"
              />
            </div>
          </div> */}

          {/* 抹茶ティラミスプリン MEMO:今後復活のため残す*/}
          {/* <>
            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid18"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="クール便 +1000円"
                  descriptionStyle="refrigeration"
                />
                <ItemPanel
                  cart={cart}
                  uid="uid19"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  setContents={
                    <div className={css.setContentsText}>
                      抹茶ティラミスプリン4個
                      <br />
                      <span className={css.fontRome}>Primo Tiramisu in Box</span>
                    </div>
                  }
                />
              </div>
            </div>

            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid20"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  setContents={
                    <div className={css.setContentsText}>
                      抹茶ティラミスプリン4個
                      <br />
                      <span className={css.fontRome}> Tiramisu Financier Box</span>
                    </div>
                  }
                />
                <ItemPanel
                  cart={cart}
                  uid="uid21"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  setContents={
                    <div className={css.setContentsText}>
                      抹茶ティラミスプリン4個
                      <br />
                      <span className={css.fontRome}>Tiramisu Macaron Box</span>
                    </div>
                  }
                />
              </div>
            </div>

            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid22"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  setContents={
                    <div className={css.setContentsText}>
                      抹茶ティラミスプリン4個
                      <span className={css.fontRome}>
                        <br />
                        Primo Tiramisu in Box
                        <br />
                        Tiramisu Financier Box
                        <br />
                        Tiramisu Macaron Box
                      </span>
                    </div>
                  }
                />
                <ItemPanel
                  cart={cart}
                  uid="uid23"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  setContents={
                    <div className={css.setContentsText}>
                      抹茶ティラミスプリン4個
                      <span className={css.fontRome}>
                        {' '}
                        <br />
                        Primo Tiramisu in Box
                        <br />
                        Tiramisu Financier Box
                        <br />
                        Tiramisu Macaron Box
                        <br />
                        Tiramisu Gelato 6Set
                      </span>
                    </div>
                  }
                />
              </div>
            </div>
          </> */}

          {/* 生チョコティラミス関連商品 */}
          <>
            <div ref={ref} className={css.goodsSection} id="topItems">
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid31"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="クール便 +1000円"
                  descriptionStyle="refrigeration"
                  soldOut={false}
                />
              </div>
            </div>
            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)} id="featured">
                <ItemPanel
                  cart={cart}
                  uid="uid32"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={false}
                  setContents={
                    <div className={css.setContentsText}>
                      生チョコティラミス16個
                      <br />
                      <span className={css.fontRome}>Primo Tiramisu in Box</span>
                    </div>
                  }
                />
                <ItemPanel
                  cart={cart}
                  uid="uid33"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={false}
                  setContents={
                    <div className={css.setContentsText}>
                      生チョコティラミス16個
                      <br />
                      <span className={css.fontRome}>Tiramisu Financier Box</span>
                    </div>
                  }
                />
              </div>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid34"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={false}
                  setContents={
                    <div className={css.setContentsText}>
                      生チョコティラミス16個
                      <br />
                      <span className={css.fontRome}>Tiramisu Macaron Box</span>
                    </div>
                  }
                />
              </div>
            </div>
            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid35"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={false}
                  setContents={
                    <div className={css.setContentsText}>
                      生チョコティラミス16個
                      <br />
                      <span className={css.fontRome}>Primo Tiramisu in Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Financier Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Macaron Box</span>
                    </div>
                  }
                />
              </div>
            </div>
          </>
          {/* 生チョコティラミス関連商品おわり */}

          {/* ティラミスまん関連商品 */}
          {/* <>
            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid25"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="クール便 +1000円"
                  descriptionStyle="refrigeration"
                  soldOut={true}
                />
              </div>
            </div>
            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid26"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={true}
                  setContents={
                    <div className={css.setContentsText}>
                      ティラミスまん5個 Bag入り
                      <br />
                      <span className={css.fontRome}>Primo Tiramisu in Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Financier Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Macaron Box</span>
                    </div>
                  }
                />
                <ItemPanel
                  cart={cart}
                  uid="uid27"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={true}
                  setContents={
                    <div className={css.setContentsText}>
                      ティラミスまん5個 Bag入り
                      <br />
                      <span className={css.fontRome}>Primo Tiramisu in Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Financier Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Macaron Box</span>
                      <br />
                      <span className={css.fontRome}>Tiramisu Gelato 6Set</span>
                    </div>
                  }
                />
              </div>
            </div>
            <div ref={ref} className={css.goodsSection}>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid28"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={true}
                  setContents={
                    <div className={css.setContentsText}>
                      ティラミスまん5個 Bag入り
                      <br />
                      <span className={css.fontRome}>Primo Tiramisu in Box</span>
                    </div>
                  }
                />
                <ItemPanel
                  cart={cart}
                  uid="uid29"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={true}
                  setContents={
                    <div className={css.setContentsText}>
                      ティラミスまん5個 Bag入り
                      <br />
                      <span className={css.fontRome}>Tiramisu Financier Box</span>
                    </div>
                  }
                />
              </div>
              <div className={classNames(css.itemPanels)}>
                <ItemPanel
                  cart={cart}
                  uid="uid30"
                  addToCart={addToCart}
                  setShow={setShow}
                  annotate={false}
                  description="送料無料"
                  descriptionStyle="freeShipping"
                  isSet={true}
                  soldOut={true}
                  setContents={
                    <div className={css.setContentsText}>
                      ティラミスまん5個 Bag入り
                      <br />
                      <span className={css.fontRome}>Tiramisu Macaron Box</span>
                    </div>
                  }
                />
              </div>
            </div>
          </> */}
          {/* ティラミスまん関連商品おわり */}

          <div ref={ref} className={css.goodsSection}>
            <div className={classNames(css.itemPanels)}>
              <ItemPanel
                cart={cart}
                uid="uid10"
                addToCart={addToCart}
                setShow={setShow}
                description="送料無料"
                annotate={false}
                descriptionStyle="freeShipping"
                isSet={true}
                setContents={
                  <div className={css.setContentsText}>
                    <span className={css.fontRome}>
                      Primo Tiramisu in Box
                      <br />
                      Tiramisu Financier Box
                      <br />
                      Tiramisu Macaron Box
                      <br />
                      Tiramisu Gelato 6Set
                    </span>
                  </div>
                }
              />
            </div>
          </div>
          <div ref={ref} className={css.goodsSection}>
            <div className={classNames(css.itemPanels)}>
              <ItemPanel
                cart={cart}
                uid="uid11"
                addToCart={addToCart}
                setShow={setShow}
                description="クール便 +1000円"
                descriptionStyle="refrigeration"
                annotate={false}
                annotation=""
              />
              <ItemPanel
                cart={cart}
                uid="uid12"
                addToCart={addToCart}
                setShow={setShow}
                description="クール便 +1000円"
                descriptionStyle="refrigeration"
                annotate={false}
                annotation=""
              />
            </div>
            <div
              className={css.productDescriptionSection}
              onClick={() => {
                setActivateDescription5(!activateDescription5);
              }}
            >
              <div className={css.sectionTitle}>商品詳細</div>

              <div className={activateDescription5 ? css.productDescription : css.displayNone}>
                <div className={css.tiramisuProductDetails}>
                  <div className={css.flexBox}>
                    <div className={css.flexItem}>
                      <img
                        src={`${process.env.PUBLIC_URL}/top/mascarpone-gelato.webp`}
                        alt=""
                        className={css.detailImage}
                      />
                      <div className={css.detailTitle}>Mascarpone Gelato</div>
                      <div className={css.detailText}>
                        マスカルポーネジェラート
                        <br />
                        北海道生乳で作られたマスカルポーネの凝縮した乳がたまらない一品。付属のカカオパウダーをかけると優しい甘みにメリハリがつき、優しいティラミスに進化します。
                      </div>

                      <div className={css.detailSubTitle}>賞味期限</div>
                      <div className={css.detailText}>なし</div>

                      <div className={css.detailSubTitle}>原材料</div>
                      <div className={css.detailText}>
                        乳製品（国内製造）、生乳、砂糖、水あめ／乳化剤、増粘多糖類、（一部に乳成分を含む）
                        <br />
                        <br />
                        無脂乳固形物：10.0% <br />
                        <br />
                        乳脂肪分：10.0%
                      </div>

                      <div className={css.detailSubTitle}>内容量</div>
                      <div className={css.detailText}>約90mL（カップすり切り）</div>
                    </div>
                    <div className={css.flexItem}>
                      <img
                        src={`${process.env.PUBLIC_URL}/top/espresso-gelato.webp`}
                        alt=""
                        className={css.detailImage}
                      />
                      <div className={css.detailTitle}> Espresso Gelato</div>
                      <div className={css.detailText}>
                        エスプレッソジェラート
                        <br />
                        本格的な苦みのあるエスプレッソにクラッシュしたビタークッキーを組合せ、しっかりとした深い味わい
                      </div>

                      <div className={css.detailSubTitle}>賞味期限</div>
                      <div className={css.detailText}>なし</div>

                      <div className={css.detailSubTitle}>原材料</div>
                      <div className={css.detailText}>
                        クッキーチップ、コーヒー、乳製品、水あめ、砂糖混合果糖ぶどう糖液糖、その他）／カラメル色素、乳化剤、増粘多糖類、香料、（一部に小麦・乳成分・落花生を含む）
                        <br />
                        <br />
                        無脂乳固形物：7.0% <br />
                        <br />
                        乳脂肪分：8.2% <br />
                        <br />
                        植物性脂肪分：1.1%（クッキー） <br />
                        <br />
                        卵脂肪分：0.1%
                      </div>

                      <div className={css.detailSubTitle}>サイズ</div>
                      <div className={css.detailText}>約90mL（カップすり切り）</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={ref} className={css.goodsSection}>
            <div className={classNames(css.itemPanels)}>
              <ItemPanel
                cart={cart}
                uid="uid13"
                addToCart={addToCart}
                setShow={setShow}
                description="送料無料"
                annotate={false}
                descriptionStyle="freeShipping"
                isSet={true}
                setContents={
                  <div className={css.setContentsText}>
                    <span className={css.fontRome}>
                      Tiramisu Gelato 6Set
                      <br />
                      Primo Tiramisu in Box
                    </span>
                  </div>
                }
              />
              <ItemPanel
                cart={cart}
                uid="uid14"
                addToCart={addToCart}
                setShow={setShow}
                description="送料無料"
                annotate={false}
                descriptionStyle="freeShipping"
                isSet={true}
                setContents={
                  <div className={css.setContentsText}>
                    <span className={css.fontRome}></span>
                    Tiramisu Gelato 6Set
                    <br />
                    Tiramisu Financier Box
                  </div>
                }
              />
            </div>
            <div className={classNames(css.itemPanels)}>
              <ItemPanel
                cart={cart}
                uid="uid15"
                addToCart={addToCart}
                setShow={setShow}
                description="送料無料"
                annotate={false}
                descriptionStyle="freeShipping"
                isSet={true}
                setContents={
                  <div className={css.setContentsText}>
                    <span className={css.fontRome}>
                      Tiramisu Gelato 6Set
                      <br />
                      Tiramisu Macaron Box
                    </span>
                  </div>
                }
              />
            </div>
          </div> */}
          <div ref={ref} className={css.goodsSection}>
            <div className={css.itemPanels}>
              <ItemPanel
                cart={cart}
                uid="uid1"
                addToCart={addToCart}
                setShow={setShow}
                description="クール便 +1000円"
                descriptionStyle="refrigeration"
                annotate={true}
                annotation="お一人様2個まで"
              />
              <ItemPanel
                cart={cart}
                uid="uid2"
                addToCart={addToCart}
                setShow={setShow}
                description="クール便 +1000円"
                descriptionStyle="refrigeration"
                annotate={true}
                annotation="お一人様2個まで"
              />
            </div>
            <div
              className={css.productDescriptionSection}
              onClick={() => {
                setActivateDescription1(!activateDescription1);
              }}
            >
              <div className={css.sectionTitle}>商品詳細</div>
              <div className={activateDescription1 ? css.productDescription : css.displayNone}>
                <div className={css.tiramisuProductDetails}>
                  <div>
                    <div className={css.detailSubTitle}>賞味期限</div>
                    <div>
                      製造日より45日間（冷凍-18度以下）
                      <br />
                      詳しくは表示ラベルをご確認ください
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className={css.detailSubTitle}>原材料</div>
                    <div>
                      マスカルポーネチーズ（イタリア製造）、クリーム、砂糖、卵黄、
                      全卵、チョコレート、コーヒー豆、アーモンドパウダー、ミネラルウォーター、
                      卵白、マルサラワイン、クリームチーズ、牛乳、きび砂糖、バター、小麦、
                      フィアンティーヌ、食塩、ゼラチン、コーヒー、カカオ豆／pH調整剤、トレハロース、
                      乳化剤、香料、モルトエキス（一部に小麦・卵・乳成分・アーモンド・ゼラチンを含む）
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className={css.detailSubTitle}>サイズ</div>
                    <div>約14cm / 約500g</div>
                  </div>
                </div>
                <div className={css.financierProductDetails}>
                  <div className={css.detailSubTitle}>配送方法について</div>
                  <br />
                  <div>
                    多くの人に場所を問わず食べてもらうため、冷凍でのお届けとなります。 ご了承下さい。
                    側面にココアパウダーの粉が表立つとカビのように見えることがありますが、冷凍庫保管し、カビが生える条件下には置いておりません。
                    ご安心ください。
                  </div>
                </div>
              </div>
            </div>
            <div className={css.itemPanels}>
              <ItemPanel
                cart={cart}
                uid="uid3"
                addToCart={addToCart}
                setShow={setShow}
                description="セット販売限定"
                annotate={false}
                descriptionStyle="setSale"
              />
              <ItemPanel
                cart={cart}
                uid="uid4"
                addToCart={addToCart}
                setShow={setShow}
                description="配送料 +800円"
                annotate={false}
                descriptionStyle="deliveryCharge"
              />
            </div>
            <div
              className={css.productDescriptionSection}
              onClick={() => {
                setActivateDescription2(!activateDescription2);
              }}
            >
              <div className={css.sectionTitle}>商品詳細</div>
              <div className={activateDescription2 ? css.productDescription : css.displayNone}>
                <div className={css.tiramisuProductDetails}>
                  <div>
                    <div className={css.detailSubTitle}>賞味期限</div>
                    <div>製造から2週間</div>
                  </div>
                  <br />
                  <div>
                    <div className={css.detailSubTitle}>原材料</div>
                    <div>
                      卵白（国産）、バター、砂糖、アーモンドパウダー、小麦、クリーミーアップパウダー（ナチュラルチーズ、乳糖、その他）、きび砂糖、発酵バター、
                      ホワイトチョコレート、ラム酒、コーヒー、カフェリーヌエスプレッソ、バニラエッセンス、食塩、（一部に小麦・卵・乳成分・アーモンド・大豆を含む）
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className={css.detailSubTitle}>サイズ</div>
                    <div>約8cm / 約16g</div>
                  </div>
                </div>
                <div className={css.financierProductDetails}>
                  <div className={css.detailSubTitle}>配送方法について</div>
                  <br />
                  <div>
                    通常では常温、冷凍便の商品と同時購入の場合は冷凍でのお届けとなります。 ご了承下さい。
                    カフェリーヌエスプレッソの粉が表立つとカビのように見えることがありますが、
                    焼成工程を踏んでおり、カビが生える条件下には置いておりません。 ご安心ください。
                  </div>
                </div>
              </div>
            </div>
            <div className={css.itemPanels}>
              <div className={css.macaronSet}>
                <ItemPanel
                  cart={cart}
                  uid="uid8"
                  addToCart={addToCart}
                  setShow={setShow}
                  description="クール便 +1000円"
                  annotate={false}
                  descriptionStyle="refrigeration"
                />
              </div>
            </div>
            <div
              className={css.productDescriptionSection}
              onClick={() => {
                setActivateDescription3(!activateDescription3);
              }}
            >
              <div className={css.sectionTitle}>商品詳細</div>

              <div className={activateDescription3 ? css.productDescription : css.displayNone}>
                <div className={css.tiramisuProductDetails}>
                  <div className={css.flexBox}>
                    <div className={css.flexItem}>
                      <img src={`${process.env.PUBLIC_URL}/top/whiteMacaron.webp`} alt="" className={css.detailImage} />
                      <div className={css.detailTitle}>Tiramisu Macaron Bianco</div>
                      <div className={css.detailText}>
                        白いティラミス風味のマカロン
                        生地はチーズ、クリームはエスプレッソが含まれていて、コーヒー好きにおすすめ
                      </div>

                      <div className={css.detailSubTitle}>賞味期限</div>
                      <div className={css.detailText}>
                        製造から3ヶ月
                        <br />
                        冷蔵で3日間
                      </div>

                      <div className={css.detailSubTitle}>原材料</div>
                      <div className={css.detailText}>
                        卵白（国産）、グラニュー糖、アーモンドプードル、シュガーパウダー、パルメザンチーズ（生乳、
                        食塩）、チョコレート（カカオマス、砂糖、ココアバター）、製菓ペースト（糖類(果糖ブドウ糖
                        液糖(国内製造)、砂糖)、コーヒー(エチオピア・モカ)）、生クリーム／添加物：膨張剤、乳化剤、
                        香料、レシチン、増粘多糖類、カラメル色素、着色料（一部に乳成分、卵、大豆、アーモンド含む）
                      </div>

                      <div className={css.detailSubTitle}>サイズ</div>
                      <div className={css.detailText}>直径約5センチ、高さ3.5センチ</div>
                    </div>
                    <div className={css.flexItem}>
                      <img src={`${process.env.PUBLIC_URL}/top/blackMacaron.webp`} alt="" className={css.detailImage} />
                      <div className={css.detailTitle}>Tiramisu Macaron Nero</div>
                      <div className={css.detailText}>
                        黒いティラミス風味のマカロン
                        生地はコーヒー、クリームはチーズが含まれていて、チーズ好きにおすすめ
                      </div>

                      <div className={css.detailSubTitle}>賞味期限</div>
                      <div className={css.detailText}>
                        製造から3ヶ月
                        <br />
                        冷蔵で3日間
                      </div>

                      <div className={css.detailSubTitle}>原材料</div>
                      <div className={css.detailText}>
                        卵白（国産）、グラニュー糖、アーモンドプードル、シュガーパウダー、ホワイトチョコレート
                        （砂糖、ココアバター、全粉乳）、生クリーム、クリームチーズ（生乳(北海道十勝産)、バターミ
                        ルク、食塩）／添加物：膨張剤、乳化剤、香料、安定剤（ローストカービンガム）、着色料
                        （一部に乳成分、卵、大豆、アーモンド含む）
                      </div>

                      <div className={css.detailSubTitle}>サイズ</div>
                      <div className={css.detailText}>直径約5センチ、高さ3.5センチ</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(css.itemPanels)}>
              <div className={css.macaronSet}>
                <ItemPanel
                  cart={cart}
                  uid="uid9"
                  addToCart={addToCart}
                  setShow={setShow}
                  description="送料無料"
                  annotate={false}
                  descriptionStyle="freeShipping"
                  isSet={true}
                  setContents={
                    <div className={css.setContentsText}>
                      Primo Tiramisu in Box
                      <br />
                      Tiramisu Macaron 2×2
                      <br />
                      Tiramisu Financier ×10
                    </div>
                  }
                />
              </div>
            </div>

            {/* <div className={css.itemPanels}>
              <ItemPanel
                cart = {cart}
                uid = "uid5"
                addToCart = {addToCart}
                setShow = {setShow}
                description="セット販売限定"
                annotate={false}
              />
            </div>
            <div
              className={css.sectionTitle}
              onClick={activateDescriptionToggle3} >
              商品詳細
            </div> 
            <div className={activateDescription3 ? css.productDescription : css.displayNone}>
              <div className={css.tiramisuProductDetails}>
                <div  >
                  <div>賞味期限</div>
                  <div>
                    製造日より45日間（冷凍-18度以下）
                  </div>
                </div>
                <br />
                <div  >
                  <div>原材料</div>
                  <div>
                    フィアンティーヌ、チョコレート、食塩、コーヒー／pH調整剤、トレハロース（一部に小麦・卵・乳成分を含む）
                  </div>
                </div>
                <br />
                <div  >
                  <div>サイズ</div>
                  <div>1瓶 / 約15g</div>
                </div>
              </div>
              <div className={css.financierProductDetails}>
                <div>
                  配送方法について<br />
                  通常では常温、冷凍便の商品と同時購入の場合は冷凍でのお届けとなります。 ご了承下さい。
                </div>
              </div>
            </div>
            <div className={css.itemPanels}>
              <ItemPanel
                cart = {cart}
                uid = "uid6"
                addToCart = {addToCart}
                setShow = {setShow}
                description="セット販売限定"
                annotate={false}
              />
            </div>
            <div
              className={css.sectionTitle}
              onClick={activateDescriptionToggle4} >
              商品詳細
            </div>
            <div className={activateDescription4 ? css.productDescription : css.displayNone}>
              <div className={css.tiramisuProductDetails}>
                <div  >
                  <div>賞味期限</div>
                  <div>
                    製造日より45日間（冷凍-18度以下）
                  </div>
                </div>
                <br />
                <div  >
                  <div>原材料</div>
                  <div>
                    コーヒー豆、クリーム、チョコレート、砂糖、コーヒー、カカオ豆（一部に乳成分を含む）
                  </div>
                </div>
                <br />
                <div  >
                  <div>サイズ</div>
                  <div>1瓶 / 約7g</div>
                </div>
              </div>
              <div className={css.financierProductDetails}>
                <div>
                  配送方法について<br />
                  通常では常温、冷凍便の商品と同時購入の場合は冷凍でのお届けとなります。 ご了承下さい。
                  <br />
                  注意点<br />
                  常温時でも固まっている場合があります。フタを閉めた状態で数回振るか、少し温めていただけますと出ることがございます。
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>

      {/* modal */}
      {cart['show'] ? <Cart close={() => setShow(false)} moveWindow={() => moveWindow()} /> : null}
      {/* { show? <Cart close={() => setShow(false)} moveWindow={() => moveWindow()} /> : null} */}
    </div>
  );
};

const ItemPanel = (props) => {
  const [displayCount, setDisplayCount] = useState(1);
  if (displayCount < 1) {
    setDisplayCount(1);
  }
  const name = props.cart['items'][props.uid]['name'] || '';
  const price = props.cart['items'][props.uid]['price'];
  const count = props.cart['items'][props.uid]['count'];
  const disabled = props.cart['items'][props.uid]['disabled'];
  const limit = props.cart['items'][props.uid]['limit'];
  const slide = props.cart['items'][props.uid]['slide'];

  const theme = props.cart['items'][props.uid]['theme'] || '';
  const uid = props.uid;

  const [slideNum, setSlideNum] = useState(1);
  const slideRight = () => {
    setSlideNum(slideNum + 1);
  };
  const slideLeft = () => {
    setSlideNum(slideNum - 1);
  };

  const ItemImage = () => {
    return (
      <picture>
        <source
          srcSet={`${process.env.PUBLIC_URL}/top/${props.cart['items'][uid][`fileName${2 * slideNum}`]}`}
          type="image/avif"
        />
        <img
          className={css.ItemPanel_img}
          src={`${process.env.PUBLIC_URL}/top/${props.cart['items'][uid][`fileName${2 * slideNum - 1}`]}`}
          alt=""
        />
      </picture>
    );
  };

  const LimitText = () => {
    // M#MO: 商品販売開始日数指定の処理を残す
    // if (
    //   /**
    //    * 12月1日を超えたら削除する
    //    */
    //   uid === 'uid16' ||
    //   uid === 'uid17' ||
    //   uid === 'uid24'
    // ) {
    //   return <p className={css.purchaseLimitText}>12月1日以降受け取り</p>;
    // }
    return null;
  };

  return (
    <ItemPanelCon id="ItemPanel" className={classNames(css.itemPanelCon, css['itemPanelCon' + theme])}>
      {props.soldOut ? (
        <div className={css.soldOut}>
          <img className={css.alert} src={`${process.env.PUBLIC_URL}/alert.svg`} alt="" />
          <div>注文が殺到しており、現在在庫がありません。 こちらの商品の見込み発送日時は、ご購入から20日後です。</div>
        </div>
      ) : (
        ''
      )}
      <div className={css.ItemPanel_imgCon}>
        <div className={classNames(css.valentine2, props.descriptionStyle === 'valentine_set' ? '' : css.displayNone)}>
          <img src={`${process.env.PUBLIC_URL}/top/valentine_img.webp`} alt="" className={css.valentine_wrap2} />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/top/leftVector.webp`}
          alt=""
          className={slideNum !== 1 ? css.leftVector : css.displayNone}
          onClick={slideLeft}
        />
        <img
          src={`${process.env.PUBLIC_URL}/top/rightVector.webp`}
          alt=""
          className={slideNum < slide ? css.rightVector : css.displayNone}
          onClick={slideRight}
        />
        <LimitText />
        <ItemImage />
      </div>
      {props.annotate && (
        <div className={`${classNames(css.annotation, props.uid === 'uid8' ? css.yellowAnnotation : '')}`}>
          <p>{props.annotation}</p>
        </div>
      )}
      <div className={classNames(`${css.ItemPanel_flex1}`, css.splitContent)}>
        <div className={classNames(css.pr8, props.isSet ? css.fw_b : css.fs14, theme === 'Split' && css.split)}>
          {name}
        </div>
        <div className={`${css.flex}`} style={{ alignItems: 'start' }}>
          <div
            onClick={() => {
              setDisplayCount(displayCount - 1);
            }}
            className={css.removebuttonCon}
          >
            <img
              src={
                // MEMO: 白黒復活のため残す 'uid17','uid24'
                ['uid18', 'uid19', 'uid20', 'uid21', 'uid22', 'uid23'].includes(props.uid)
                  ? `${process.env.PUBLIC_URL}/removebutton_white.svg`
                  : `${process.env.PUBLIC_URL}/removebutton.svg`
              }
              alt=""
              className={classNames(
                `${css.removebutton}`,
                css.adjustZIndex,
                (limit !== null && count + displayCount >= limit) || count === 1 ? css.disable : ''
              )}
            />
          </div>
          <div className={css.displayCountCon}>
            <div className={classNames(css.displayCount, css.adjustZIndex)}>{displayCount}</div>
          </div>
          <div className={css.addbuttonCon}>
            <div className={classNames(`${css.addbutton}`, css.adjustZIndex)}>
              <img
                onClick={() => {
                  setDisplayCount(displayCount + 1);
                }}
                // MEMO: 'uid17',
                src={
                  ['uid18', 'uid19', 'uid20', 'uid21', 'uid22', 'uid23', 'uid24'].includes(props.uid)
                    ? `${process.env.PUBLIC_URL}/addbutton_white.svg`
                    : `${process.env.PUBLIC_URL}/addbutton.svg`
                }
                alt=""
                className={classNames(limit !== null && count + displayCount >= limit ? css.disable : '')}
              />
            </div>
          </div>
        </div>
      </div>
      {props.isSet ? (
        <div className={`${css.ItemPanelSetContents}`}>
          <div>{props.setContents}</div>
          <div className={classNames(css.setItemPanelPriceBox1)}>
            <div className={css.itemPanelPrice}>￥{price.toLocaleString()}</div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={`${css.ItemPanel_flex2}`}>
        <div className={props.isSet ? css.setItemPanelPriceBox2 : css.itemPanelPriceBox}>
          {props.isSet ? '' : <div className={`${css.itemPanelPrice}`}>￥{price.toLocaleString()}</div>}
          <div className={classNames(css[props.descriptionStyle])}>{props.description}</div>
        </div>
        <MiniBoxButton
          onClick={() => {
            if (!disabled) {
              props.addToCart(props.uid, count + displayCount);
              props.setShow(true);
              setDisplayCount(1);
            }
          }}
          // text="カートへ追加"
          text={'カートへ追加'}
          // MEMO: 白黒復活のため残す
          // buttonColorWhite={['uid17', 'uid24'].includes(props.uid) ? true : false}
          buttonColorWhite={false}
          disabled={disabled}
          name={name}
          className={classNames(css.adjustZIndex, props.isSet ? css.setItemButton : '')}
        />
        {props.theme?.key}
      </div>
    </ItemPanelCon>
  );
};
