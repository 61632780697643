import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useStripe } from '@stripe/react-stripe-js';

import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db, functions } from '../../../zz_general/utils/configs/firebase';

import { importAll } from '../../utils/image';
import css from './index.module.scss';

import { CustomerInfo_delivery, DeliveryInfo_delivery, OrderDetails } from '../../components';
import { LinkButton } from '../../components/linkButton';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  LPPaymentState,
  LPCustomerState,
  LPDeliveryState,
  LPAddressState,
  LPCartState,
  LPtotalPriceState,
  LPtotalCountState,
  LPErrorPageState,
} from '../../store/atoms';
import { termsAndPolicyState } from '../../../lp-legacy/atoms';
import { httpsCallable } from 'firebase/functions';
import { FormHeader } from '../../components/fiveStepBar';
import dayjs from 'dayjs';
import { Loading } from '../../components/loading';
import { Error } from '../../components/error';

export const LPConfirmDelivery = () => {
  // #region const
  const navigate = useNavigate();
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const inputFormWidth = [340, 512, 720];
  const linkButtonHeight = [48, 52, 60];
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const params = { reservationId: query.get('reservationsId') || '' };
  const [pageIsLoading, setPageIsLoading] = useState(params.reservationId ? true : false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  // const [showError, setShowError] = useState({ status: false, message: '', code: '' });
  const [errorText, setErrorText] = useState('');

  const stripe = useStripe();

  const cart = useRecoilValue(LPCartState);
  const totalPrice = useRecoilValue(LPtotalPriceState);
  const totalCount = useRecoilValue(LPtotalCountState);
  const total = { totalCount: totalCount, totalPrice: totalPrice };
  const payment = useRecoilValue(LPPaymentState);
  const customer = useRecoilValue(LPCustomerState);
  const delivery = useRecoilValue(LPDeliveryState);
  const address = useRecoilValue(LPAddressState);
  const setTermsAndPolicy = useSetRecoilState(termsAndPolicyState);
  const [errorPage, setErrorPage] = useRecoilState(LPErrorPageState);

  // MEMO: Firebaseに送信するデータ
  const reservationData = {
    reservationsId: customer.reservationId,
    // MEMO: 受け渡しステータス（no:未受け渡し、yes:受け渡し済み、cancel:キャンセル）
    deliveryStatus: 'no',
    // 顧客情報
    fName: customer.firstName,
    lName: customer.lastName,
    fNameKana: customer.firstNameKana,
    lNameKana: customer.lastNameKana,
    emailAddress: customer.mailAddress,
    phoneNumber: customer.phoneNum,
    // 住所情報
    address: address,
    // 商品情報
    items: cart.items,
    total: total,
    // 受取情報
    howToGet: {
      flag: 'delivery',
      receiveMethod: {
        delivery: {
          status: 'no',
          date: dayjs(delivery.date).toDate(),
          time: delivery.time,
        },
      },
    },
    // メール送信を行ったか
    mailStatus: '',
    // 決済情報
    paymentMethod: payment.mode,
    creditBrand: payment?.stripe?.paymentMethod?.card?.brand || '',
    creditNumber: payment?.stripe?.paymentMethod?.card?.last4 || '',
    // 予約確認URL
    url: `https://bene-regalo.com/delivery/customer-page?reservationsId=${customer.reservationId}`,
    // 予約日時
    reserveDate: new Date(),
    // reserveDate: new Date().toLocaleString('ja-JP', options),
  };

  // MEMO: Firebaseに送信するデータ
  const customerData = {
    // 顧客情報
    fName: customer.firstName,
    lName: customer.lastName,
    fNameKana: customer.firstNameKana,
    lNameKana: customer.lastNameKana,
    emailAddress: customer.mailAddress,
    phoneNumber: customer.phoneNum,
    // 案内メールを受け取るか
    receiveDM: customer.receiveDM,
  };

  // #endregion
  // #region function
  const updateTermsAndPolicy = (key, boolean) => {
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = boolean;
      return future;
    });
  };

  const updateErrorPage = (show, message, code) => {
    setErrorPage((current) => ({
      ...current,
      show: show,
      message: message,
      code: code,
    }));
  };

  // MEMO: Firebaseに予約情報と顧客情報を送信
  const sendDataToFireStore = async (paymentStatus) => {
    const reservationsCollectionRef = collection(
      db,
      'LP',
      process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
      'reservations'
    );
    const customersCollectionRef = collection(
      db,
      'LP',
      process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
      'customers'
    );
    const reservationDocRef = doc(reservationsCollectionRef, customer.reservationId);
    const customerDocRef = doc(customersCollectionRef, customer.reservationId);
    await setDoc(reservationDocRef, { ...reservationData, paymentStatus: paymentStatus });
    await setDoc(customerDocRef, customerData);
  };

  // MEMO: Firebaseの決済ステータスを更新
  const updatePaymentStatus = async (reservationId, paymentStatus) => {
    const collectionRef = collection(
      db,
      'LP',
      process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
      'reservations'
    );
    const docRef = doc(collectionRef, reservationId);
    await updateDoc(docRef, { paymentStatus: paymentStatus });
  };

  // MEMO: Firebaseから予約情報を取得
  const fetchPaymentStatus = async (reservationId) => {
    const collectionRef = collection(
      db,
      'LP',
      process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
      'reservations'
    );
    const docRef = doc(collectionRef, reservationId);
    const docSnap = await getDoc(docRef);
    return docSnap.data().paymentStatus;
  };

  const sendEmail = async (reservationId) => {
    const LPDeliverySendEmail = httpsCallable(
      functions,
      process.env.REACT_APP_IS_PROD === 'true' ? 'LPDelivery-sendEmail' : 'LPDeliveryTest-sendEmail'
    );
    return await LPDeliverySendEmail(reservationId);
  };

  const sendError = async (error) => {
    const sendError = httpsCallable(functions, 'lark-sendError');
    return await sendError(error);
  };

  // MEMO : 5分後にPayPayの決済ステータスを確認する関数をセットする
  const setScheduledFunction = async () => {
    // MEMO: DBへのデータ送信
    setButtonIsLoading(false);
    setPageIsLoading(true);
    try {
      await sendDataToFireStore('pending');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error getting documents: ', error);
      setPageIsLoading(false);
      setErrorText(
        `予期せぬエラーが発生しました。お手数ですが、時間をおくか、入力内容をお確かめの上、再度お試しください。エラーコード：P01-${customer.reservationId}`
      );
      return;
    }

    // MEMO: 5分後関数をセット
    try {
      const scheduledFunction = httpsCallable(
        functions,
        process.env.REACT_APP_IS_PROD === 'true' ? 'payPay-scheduledFunction' : 'payPayTest-scheduledFunction'
      );
      const res = await scheduledFunction(customer.reservationId);
      // eslint-disable-next-line no-console
      console.log('res: ', res.data);

      if (res.data === 'Function scheduled successfully') {
        setPageIsLoading(false);
        window.open(payment?.payPay?.paymentLink, '_blank');
      } else {
        setPageIsLoading(false);
        setErrorText(
          `PayPayアプリへの遷移へ失敗しました。お手数ですが、時間をおくか、入力内容をお確かめの上、再度お試しください。エラーコード：P02-${customer.reservationId}`
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error setting scheduled function: ', error);
      setErrorText(
        `PayPayアプリへの遷移へ失敗しました。お手数ですが、時間をおくか、入力内容をお確かめの上、再度お試しください。エラーコード：P03-${customer.reservationId}`
      );
    }
  };

  // MEMO: Stripeの支払いを確定する
  // MEMO: 成功時には、Firestoreへのデータの送信とthanksページへの遷移を行う
  const confirmStripe = async () => {
    setButtonIsLoading(false);
    setPageIsLoading(true);
    // MEMO: Stripeの支払いを確定する
    try {
      const confirmCardPaymentRes = await stripe.confirmCardPayment(payment.stripe.paymentIntent.client_secret, {
        payment_method: payment.stripe.paymentMethod.id,
      });
      if (confirmCardPaymentRes.paymentIntent && confirmCardPaymentRes.paymentIntent.status === 'succeeded') {
        // eslint-disable-next-line no-console
        console.log('stripe: success');
      } else if (confirmCardPaymentRes.error && confirmCardPaymentRes.error.message) {
        // MEMO: エラー時の処理
        // eslint-disable-next-line no-console
        console.log('stripe error: ', confirmCardPaymentRes.error.message);
        setPageIsLoading(false);
        setErrorText(
          `クレジットカードの問い合わせが失敗しました。お手数ですが、時間をおくか、入力内容をお確かめの上、再度お試しください。エラーコード：S01-${customer.reservationId}`
        );
        return;
      } else {
        // MEMO: 予期しないエラー発生時の処理
        // eslint-disable-next-line no-console
        console.log('stripe unexpected error: ');
        setPageIsLoading(false);
        setErrorText(
          `クレジットカードの問い合わせで予期せぬエラーが発生しました。お手数ですが、時間をおくか、入力内容をお確かめの上、再度お試しください。エラーコード：S02-${customer.reservationId}`
        );
        return;
      }
    } catch (error) {
      // MEMO: 予期しないエラー発生時の処理
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      setPageIsLoading(false);
      setErrorText(
        `クレジットカードの問い合わせで予期せぬエラーが発生しました。お手数ですが、時間をおくか、入力内容をお確かめの上、再度お試しください。エラーコード：S03-${customer.reservationId}`
      );
      return;
    }

    // MEMO: DBへのデータ送信
    try {
      await sendDataToFireStore('completed');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      setPageIsLoading(false);
      updateErrorPage(
        true,
        '決済は完了しましたが、処理中にエラーが発生しました。お手数ですが、お問い合わせフォームよりご連絡ください。',
        `S04-${customer.reservationId}`
      );
      await sendError(
        `Stripe決済後、DB送信でエラーが発生しました。\n関数名：sendDataToFireStore\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：S04-${customer.reservationId}\nエラー内容：${error}\nユーザー情報\n名前：${customer.lastName} ${customer.firstName}（${customer.lastNameKana} ${customer.firstNameKana}）\nメールアドレス：${customer.mailAddress}\n`
      );
      return;
    }

    // MEMO: メール送信&Lark通知
    try {
      const sendEmailRes = await sendEmail(customer.reservationId);
      // MEMO: error handling for sendEmail
      if (sendEmailRes.data.success) {
        // eslint-disable-next-line no-console
        console.log('email sent successfully');
      } else {
        // eslint-disable-next-line no-console
        console.log('error sending email: ', sendEmailRes.data.error);
        await sendError(
          `Stripe決済後、メール送信でエラーが発生しました。\n関数名：sendEmail\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：S05-${customer.reservationId}\nエラー内容：${sendEmailRes.data.error}\nユーザー情報\n名前：${customer.lastName} ${customer.firstName}（${customer.lastNameKana} ${customer.firstNameKana}）\nメールアドレス：${customer.mailAddress}\n`
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      await sendError(
        `Stripe決済後、メール送信でエラーが発生しました。\n関数名：sendEmail\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：S06-${customer.reservationId}\nエラー内容：${error}\nユーザー情報\n名前：${customer.lastName} ${customer.firstName}（${customer.lastNameKana} ${customer.firstNameKana}）\nメールアドレス：${customer.mailAddress}\n`
      );
    }

    // MEMO: thanksページへの遷移
    setPageIsLoading(false);
    navigate(`/delivery/customer-page?reservationsId=${customer.reservationId}`, { replace: true });
  };

  // MEMO: PayPayの決済のステータスをPayPayAPIに問い合わせる
  // MEMO: 成功時には、Firestoreへのデータの送信とthanksページへの遷移を行う
  const confirmPayPay = async (reservationId) => {
    const fetchPaymentStatusRes = await fetchPaymentStatus(reservationId);

    // MEMO: すでに決済済みの場合の処理
    if (fetchPaymentStatusRes === 'completed') {
      setPageIsLoading(false);
      navigate(`/delivery/customer-page?reservationsId=${reservationId}`, { replace: true });
      return;
    }

    // MEMO: PayPayAPIに問い合わせる
    try {
      const confirmPaymentStatus = httpsCallable(
        functions,
        process.env.REACT_APP_IS_PROD === 'true' ? 'payPay-confirmPaymentStatus' : 'payPayTest-confirmPaymentStatus'
      );
      const confirmPaymentStatusRes = await confirmPaymentStatus({ merchantPaymentId: reservationId });

      // MEMO: error handling for confirmPaymentStatus
      if (confirmPaymentStatusRes.data.data.status === 'COMPLETED') {
        // eslint-disable-next-line no-console
        console.log('paypay: completed');
      } else {
        // eslint-disable-next-line no-console
        console.log('paypay: ', confirmPaymentStatusRes.data.data.status);
        // MEMO: 決済未完了時の処理
        setPageIsLoading(false);
        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      setPageIsLoading(false);
      updateErrorPage(
        true,
        '処理中にエラーが発生しました。お手数ですが、お問い合わせフォームよりご連絡ください。',
        `P04-${reservationId}`
      );
      await sendError(
        `PayPay決済のstatus確認でエラーが発生しました。\n関数名：confirmPaymentStatus\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：P04-${reservationId}\nエラー内容：${error}\n予約ID：${reservationId}\n`
      );
      return;
    }

    // MEMO: Firestoreの決済ステータス更新
    try {
      await updatePaymentStatus(reservationId, 'completed');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      setPageIsLoading(false);
      updateErrorPage(
        true,
        '処理中にエラーが発生しました。お手数ですが、お問い合わせフォームよりご連絡ください。',
        `P05-${reservationId}`
      );
      await sendError(
        `PayPay決済後のstatus更新でエラーが発生しました。\n関数名：updatePaymentStatus\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：P05-${reservationId}\nエラー内容：${error}\n予約ID：${reservationId}\n`
      );
      return;
    }

    // MEMO: メール送信&Lark通知
    try {
      const sendEmailRes = await sendEmail(reservationId);
      // MEMO: error handling for sendEmail
      if (sendEmailRes.data.success) {
        // eslint-disable-next-line no-console
        console.log('email sent successfully');
      } else {
        // eslint-disable-next-line no-console
        console.log('error sending email: ', sendEmailRes.data.error);
        await sendError(
          `PayPay決済後のメール送信でエラーが発生しました。\n関数名：updatePaymentStatus\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：P06-${reservationId}\nエラー内容：${sendEmailRes.data.error}\n予約ID：${reservationId}\n`
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('catch error: ', error);
      await sendError(
        `PayPay決済後のメール送信でエラーが発生しました。\n関数名：updatePaymentStatus\nファイル名：src/lp/delivery/confirm/index.js\nエラーコード：P07-${reservationId}\nエラー内容：${error}\n予約ID：${reservationId}\n`
      );
    }

    // MEMO: thanksページへの遷移
    setPageIsLoading(false);
    navigate(`/delivery/customer-page?reservationsId=${reservationId}`, { replace: true });
  };

  // MEMO : 確定ボタンの処理
  const handleLinkClick = async () => {
    setButtonIsLoading(true);
    if (payment?.mode === 'stripe') {
      // MEMO : Stripeの場合
      confirmStripe();
    } else if (payment?.mode === 'payPay') {
      // MEMO : PayPayの場合
      setScheduledFunction();
    } else {
      // eslint-disable-next-line no-console
      console.error('error: payment mode is not set');
      setButtonIsLoading(false);
    }
  };

  // #endregion
  // #region useEffect
  useEffect(() => {
    // MEMO : デバッグ用のログ
    if (process.env.REACT_APP_IS_PROD !== 'true') {
      // eslint-disable-next-line no-console
      console.log('delivery', delivery);
      // eslint-disable-next-line no-console
      console.log('customer', customer);
      // eslint-disable-next-line no-console
      console.log('address', address);
      // eslint-disable-next-line no-console
      console.log('payment', payment);
    }

    // MEMO : 直接アクセスされた場合のリダイレクト
    if (total.totalCount === 0 && pageIsLoading === false) {
      navigate('/');
    }

    // MEMO : 利用規約とプライバシーポリシーのページ遷移用の設定
    setTermsAndPolicy((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['backPage'] = '/delivery/confirm';
      return future;
    });

    // MEMO : クエリパラメータから予約IDを取得した場合の処理
    if (params.reservationId) {
      confirmPayPay(params.reservationId);
    }

    // MEMO : ユーザーがタブ切り替えによりページに戻ってきた場合の処理
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setPageIsLoading(true);
        confirmPayPay(customer.reservationId);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  // #endregion

  // #region return
  if (errorPage.show) return <Error />;
  if (pageIsLoading) return <Loading />;
  return (
    <div>
      <FormHeader title="確認" step={4} />
      <div className={css.reservationsContents}>
        <OrderDetails product={cart.items} total={total} />
        <hr className={classNames(css.lineM)} />
        <CustomerInfo_delivery customer={customer} address={address} />
        <hr className={classNames(css.lineM)} />
        <DeliveryInfo_delivery address={address} delivery={delivery} />
        <hr className={classNames(css.lineM)} />
        <div className={css.title}>決済</div>
        {payment?.mode === 'stripe' && (
          <div className={classNames(css.cardNum)}>
            <img src={image['credit_card.svg']} className={classNames(css.cardIcon)} />
            <span className={css.text}>
              クレジットカード
              <br />
              <span className={classNames(css.cardFlex)}>
                <img
                  src={image[`${payment?.stripe?.paymentMethod?.card?.brand}.webp`]}
                  className={classNames(css.cardBrand)}
                />
                <span className={css.cardText}>****-****-****-{payment?.stripe?.paymentMethod?.card?.last4}</span>
              </span>
            </span>
          </div>
        )}
        {payment?.mode === 'payPay' && <img src={image['pay_pay.webp']} className={classNames(css.payPay)} />}
        <div className={classNames(css.checkBoxWrap)}>
          <img
            src={image[`check_box_${isButtonActive ? 'active' : 'passive'}.svg`]}
            className={classNames(css.checkBox)}
            onClick={() => {
              setIsButtonActive(!isButtonActive);
            }}
          />
          <Link
            style={{
              color: '#3EB370',
              marginTop: '12px',
              textDecoration: 'underline',
            }}
            to={'/policy'}
            rel="noopener noreferrer"
          >
            <span
              onClick={() => {
                updateTermsAndPolicy('terms', true);
              }}
            >
              利用規約
            </span>
          </Link>
          <span>・</span>
          <Link
            style={{
              color: '#3EB370',
              marginTop: '12px',
              textDecoration: 'underline',
            }}
            to={'/policy'}
            rel="noopener noreferrer"
          >
            <span
              onClick={() => {
                updateTermsAndPolicy('policy', false);
              }}
            >
              プライバシーポリシー
            </span>
          </Link>
          に同意する
        </div>
        {errorText && (
          <div>
            <hr className={classNames(css.lineM)} />
            <div className={css.title} style={{ color: 'red' }}>
              {errorText}
            </div>
          </div>
        )}
        <LinkButton
          className={classNames(css.linkButton)}
          width={inputFormWidth}
          height={linkButtonHeight}
          text={payment.mode === 'payPay' ? 'PayPayへ遷移する' : '注文を確定する'}
          color="black"
          active={isButtonActive}
          loading={buttonIsLoading}
          onClick={() => {
            handleLinkClick();
          }}
        />
        <LinkButton
          width={inputFormWidth}
          height={linkButtonHeight}
          text="決済に戻る"
          color="white"
          arrow="left"
          active={true}
          onClick={() => {
            navigate('/delivery/payment');
          }}
        />
      </div>
    </div>
  );
};
