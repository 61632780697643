import { useState } from 'react';
import { MdClear, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { BoxButton, TextButton } from '..';

import { useRecoilState, useRecoilValue } from 'recoil';
import { LPCartState, LPtotalCountState, LPtotalPriceState, sumUpCart } from '../../../store/atoms';

import css from '../../index.module.scss';
import classNames from 'classnames';
import { CartIconCon, CountIcon, ItemCon, ItemCountCon, TotalPrice } from './scss';

export const Cart = (props) => {
  const [cart, setCart] = useRecoilState(LPCartState);
  const [isOpen, setIsOpen] = useState(false);
  const totalPrice = useRecoilValue(LPtotalPriceState);
  const totalCount = useRecoilValue(LPtotalCountState);
  const navigate = useNavigate();

  const setShow = (event, path) => {
    event.preventDefault();
    setCart((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['show'] = false;
      return future;
    });
    navigate(`/${path}/customer-info`);
  };

  const addToCart = (key, count) => {
    setCart((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future['items'][key]['count'] = count;
      return future;
    });
  };

  const items = [];

  for (const key in cart['items']) {
    if (cart['items'][key]['count'] > 0) {
      items.push(
        <CartItem
          key={key}
          name={cart['items'][key]['name']}
          price={cart['items'][key]['price']}
          count={cart['items'][key]['count']}
          fileName={cart['items'][key]['fileName']}
          onClickAdd={(count) => {
            addToCart(key, count);
          }}
          disabled={cart['items'][key]['disabled']}
        />
      );
    }
  }

  const cartContent =
    totalCount > 0 ? (
      items
    ) : (
      <div className={css.pd24_0}>
        <img className={classNames(css.vertAlM, css.mr12)} src={`${process.env.PUBLIC_URL}/15.svg`} alt="" />
        <span>カートは空っぽです</span>
      </div>
    );

  const bottomContent =
    totalCount > 0 ? (
      <div className={css.bottomContent}>
        <div className={css.fee}>
          <div className={css.postage}>
            <span className={css.span1}>見込み送料 </span>
            <span className={css.span2}>¥</span>
            <span className={css.span3}>{(totalPrice - sumUpCart(cart)).toLocaleString()}</span>
            <span className={css.span4}>(税込)</span>
          </div>
          <TotalPrice>
            <CartIconCon>
              <img className={classNames(css.vertAlM, css.cart_icon)} src={`${process.env.PUBLIC_URL}/15.svg`} alt="" />
              <CountIcon>{totalCount}</CountIcon>
            </CartIconCon>
            <span className={`${css.priceFont}`}>合計</span>
            <span className={`${css.priceFont2}`}>¥</span>
            <span className={`${css.priceFont3} ${css.fw_b}`}>{totalPrice.toLocaleString()}</span>
            <span className={`${css.priceFont}`}>(税込)</span>
          </TotalPrice>
        </div>
        {(() => {
          if (
            cart['items']['uid3']['count'] === 0 ||
            cart['items']['uid5']['count'] === 0 ||
            cart['items']['uid6']['count'] === 0
          ) {
            return (
              <>
                <div className={css.addTextBox}>
                  <p>セット商品はいかがですか？</p>
                  <p>送料そのままでご追加できます。</p>
                </div>

                {(() => {
                  return (
                    <div className={classNames(css.upSellBox, isOpen ? css.open : css.close)}>
                      <div className={classNames(css.upCellItems, isOpen ? css.open : css.close)}>
                        {(() => {
                          if (cart['items']['uid3']['count'] === 0) {
                            return (
                              <UpCellItems
                                key={'uid3'}
                                uid={'uid3'}
                                name={cart['items']['uid3']['name']}
                                price={cart['items']['uid3']['price']}
                                count={cart['items']['uid3']['count']}
                                fileName={cart['items']['uid3']['fileName']}
                                onClickAdd={(count) => {
                                  addToCart('uid3', count);
                                }}
                                disabled={cart['items']['uid3']['disabled']}
                              />
                            );
                          }
                        })()}

                        {(() => {
                          if (cart['items']['uid5']['count'] === 0) {
                            return (
                              <UpCellItems
                                key={'uid5'}
                                uid={'uid5'}
                                name={cart['items']['uid5']['name']}
                                price={cart['items']['uid5']['price']}
                                count={cart['items']['uid5']['count']}
                                fileName={cart['items']['uid5']['fileName']}
                                onClickAdd={(count) => {
                                  addToCart('uid5', count);
                                }}
                                disabled={cart['items']['uid5']['disabled']}
                              />
                            );
                          }
                        })()}

                        {(() => {
                          if (cart['items']['uid6']['count'] === 0) {
                            return (
                              <UpCellItems
                                key={'uid6'}
                                uid={'uid6'}
                                name={cart['items']['uid6']['name']}
                                price={cart['items']['uid6']['price']}
                                count={cart['items']['uid6']['count']}
                                fileName={cart['items']['uid6']['fileName']}
                                onClickAdd={(count) => {
                                  addToCart('uid6', count);
                                }}
                                disabled={cart['items']['uid6']['disabled']}
                              />
                            );
                          }
                        })()}
                      </div>
                    </div>
                  );
                })()}

                <div
                  className={css.isSetShow}
                  onClick={() => {
                    isOpen ? setIsOpen(false) : setIsOpen(true);
                  }}
                >
                  {(() => {
                    if (isOpen) {
                      return (
                        <>
                          <MdKeyboardArrowUp className={`${css.wh12}`} />
                          <p>セット商品を閉じる</p>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <MdKeyboardArrowDown className={`${css.wh12}`} /> <p>&nbsp;セット商品をもっと見る</p>
                        </>
                      );
                    }
                  })()}
                </div>
              </>
            );
          }
        })()}
        <div
          onClick={(e) => {
            setShow(e, 'delivery');
          }}
          className={css.purchase}
          style={{ marginTop: '12px' }}
        >
          <BoxButton text="購入手続きへ進む" />
        </div>
        {/* <div
          onClick={(e) => {
            setShow(e, 'takeout');
          }}
          className={css.purchase}
          style={{ marginTop: '12px' }}
        >
          <BoxButton text="購入手続きへ進む(受取)" />
        </div> */}
        <div className={css.continue}>
          <TextButton onClick={props.close} text="買い物を続ける" />
        </div>
      </div>
    ) : (
      <div className={css.choose}>
        <BoxButton onClick={props.moveWindow} text="ティラミスを選ぶ" className={classNames(css.mt32, css.mb24)} />
      </div>
    );

  return (
    <div className={css.CartModal}>
      <div onClick={props.close} className={css.ModalBack}></div>
      <div className={css.Modal}>
        <div className={css.topContent}>
          <button onClick={props.close}>
            <MdClear className={`${css.wh24}`} />
          </button>
          <img src={`${process.env.PUBLIC_URL}/bene.svg`} alt="" className={classNames(css.cartLogo)} />
          <span className={`${css.vertAlM} ${css.hei20}`}>カート</span>
        </div>
        <div className={classNames(css.cartContent, css.items)}>{cartContent}</div>
        {bottomContent}
      </div>
    </div>
  );
};

function UpCellItems(props) {
  return (
    <div
      className={css.upCellItem}
      onClick={() => {
        props.onClickAdd(props.count + 1);
      }}
    >
      <div className={css.textBox}>
        <div className={`${css.name} `}>
          {(() => {
            if (props.uid === 'uid5' || props.uid === 'uid6') {
              return '【追加】';
            }
          })()}
          {props.name}
        </div>
        <div className={`${css.price} `}>￥{props.price.toLocaleString()}</div>
      </div>
      <div className={css.box}>
        <div className={css.imgBox}>
          <img src={`${process.env.PUBLIC_URL}/top/${props.fileName}`} alt="" className={css.img} />
        </div>
        <div
          onClick={() => {
            props.onClickAdd(props.count + 1);
          }}
          className={css.addTextBox}
        >
          <p className={css.text}>{'追加する'}</p>
        </div>
      </div>
    </div>
  );
}

function CartItem(props) {
  return (
    <div className={css.CartItem}>
      <ItemCon>
        <div className={`${css.item_font1} `}>{props.name}</div>
        <div className={`${css.item_font2} `}>￥{props.price.toLocaleString()}</div>
      </ItemCon>

      <ItemCountCon>
        <div className={css.item_img_con1}>
          <img src={`${process.env.PUBLIC_URL}/top/${props.fileName}`} alt="" className={css.item_img} />
        </div>
        <div className={`${css.buttons}  `}>
          <div className={css.item_img_con2}>
            <img src={`${process.env.PUBLIC_URL}/top/${props.fileName}`} alt="" className={css.item_img} />
          </div>

          <div className={css.buttonsContent}>
            <div
              onClick={() => {
                props.onClickAdd(props.count - 1);
              }}
              className={css.buttonsBox}
            >
              <img src={`${process.env.PUBLIC_URL}/removebutton.svg`} alt="" className={`${css.wid100}`} />
            </div>
            <span>{props.count}</span>

            <div className={css.buttonsBox}>
              <img
                onClick={() => {
                  props.onClickAdd(props.count + 1);
                }}
                src={`${process.env.PUBLIC_URL}/addbutton.svg`}
                alt=""
                className={classNames(props.disabled ? css.disable : '')}
              />
            </div>
          </div>
        </div>
      </ItemCountCon>
    </div>
  );
}
