import { useRecoilState } from 'recoil';
import { adminState } from '../store/atom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from './scss';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

export const AdminHome = () => {
  // #region Variables
  const auth = getAuth();
  const navigate = useNavigate();
  const [_admin, setAdmin] = useRecoilState(adminState);

  // #endregion
  // #region Functions

  // #endregion
  // #region UseEffects
  useEffect(() => {
    // MEMO : ログイン状態を監視
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid !== process.env.REACT_APP_ADMIN_UID) {
          navigate('/admin/sign-in');
        }
      } else {
        navigate('/admin/sign-in');
      }
    });

    // コンポーネントがアンマウントされる際にリスナーを解除
    return () => unsubscribe();
  }, []);
  // #endregion
  return (
    <Wrapper>
      <h1>Dashboard</h1>
      <p
        onClick={() => {
          navigate('/admin/item');
        }}
      >
        item管理
      </p>
      <p
        onClick={() => {
          setAdmin((current) => {
            const future = JSON.parse(JSON.stringify(current));
            future['testPurchase'] = true;
            return future;
          });
          navigate('/lp-beta');
        }}
      >
        テスト購入
      </p>
      <p
        onClick={() => {
          signOut(auth);
        }}
      >
        ログアウト
      </p>
    </Wrapper>
  );
};
